import MasonryGrid from 'material/grid/MasonryGrid'

import CollectionFilters from './collection/CollectionFilters'
import StudioItem from 'studio/StudioItem'
import StudioManager from 'rest/StudioManager'
import StudioPager from 'material/pagination/StudioPager'


class Collection extends StudioManager {
    constructor(props) {super(props)
        this.state = {
            items: [],
            page: 1,
            count: 0,
            filterByForSale: false,
            filterByTitle: '',
            filterByFileName: '',
            filterByFeatured: false,
        }
        this.collection = this.props.match.params.collection || null
        this.setItems = this.setItems.bind(this)
        this.setFilters = this.setFilters.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        prevState !== this.state &&
        this.props.history.replace({
            pathname: `/collection/${this.collection}/${this.state.page}`
        })
    }

    render() {
        return (
            <>
                <CollectionFilters
                collection={this.collection}
                setItems={this.setItems}
                setFilters={this.setFilters}
                status={() => this.returnStatus()}/>
                {this.renderPager()}
            </>
        )
    }

    makeRequest(event=null, value=null) {
        let payload = this.collection ? ({
            collection: this.collection,
            page: value || this.state.page,
            filterByForSale: this.state.filterByForSale,
            filterByTitle: this.state.filterByTitle,
            filterByFileName: this.state.filterByFileName,
            filterByFeatured: this.state.filterByFeatured,
        }) : null

        return payload ? (
            this.REST.GET('studio_collection', payload)
            .then(response => response.data.payload && (
                this.setState({
                    items: response.data.payload.items,
                    page: response.data.payload.page,
                    count: response.data.payload.count,
                }),
                window.scroll({top: 0})
            ))
        ) : null
    }

    setItems(update) {
        return this.setState({
            items: update.items || [],
            page: update.page || 1,
            count: update.count || 0,
        })
    }

    setFilters(update) {
        return this.setState({
            [update.filterType]: update.value,
            page: 1,  // paginate back to the first page
        })
    }

    returnStatus() {
        // status = child.state
        return {...this.state}
    }

    renderItems() {
        const children = this.renderGridItems()

        return children ? (
            <MasonryGrid children={children}/>
        ) : null
    }

    renderGridItems() {
        return (
            this.state.items?.map((item, index) => {
                return (
                    <div key={String(index)} id={item.title || item.img_file_name}>
                        <StudioItem {...item}/>
                    </div>
                )
            })
        )
    }

    renderPager() {
        return (
            <StudioPager
            top bottom
            state={this.state}
            makeRequest={() => this.makeRequest()}
            children={this.renderItems()}
            onChange={(event, value) => this.makeRequest(event, value)}/>
        )
    }
}


export default Collection
