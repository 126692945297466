import React from 'react'


class PreviewModal extends React.Component {
    render() {
        return (
            <div className='preview-modal-wrapper' onClick={() => this.props.handleClose()}>
                <div className='preview-modal-container'>
                    <div className='preview-modal'>
                        {this.renderPanel()}
                        {this.renderImage()}
                    </div>
                </div>
            </div>
        )
    }

    renderPanel() {
        return (
            <div className='preview-modal-panel'>
                <p>{this.props.title}</p>
            </div>
        )
    }

    renderImage() {
        return (
            <div className='preview-modal-img'>
                <img src={this.props.img_64} alt={this.props.title}/>
            </div>
        )
    }
}


export default PreviewModal
