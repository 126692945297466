import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {Route} from 'react-router-dom'

import Auth from 'app/auth/Auth'
import BossPanel from 'app/boss/BossPanel'
import Navbar from 'app/navigation/Navbar'
import Orders from 'app/orders/Orders'
import Site from 'app/site/Site'
import StudioHelmet from 'studio/StudioHelmet'
import StudioCart from 'app/cart/StudioCart'
import {toaster} from 'util/toast/Toast'


function registerRoutes() {
    return (
        <>
            <Route path={Auth.routes} exact component={Auth}/>
            <Route path={BossPanel.routes} exact component={BossPanel}/>
            <Route path={Orders.routes} exact component={Orders}/>
            <Route path={Site.routes} exact component={Site}/>
            <Route path={StudioCart.routes} exact component={StudioCart}/>
        </>
    )
}


function MainRouter() {
    return (
        <Router>
            <div className='app-wrapper'>
                <Navbar/>
                {renderApp()}
                {renderAppFooter()}
            </div>
        </Router>
    )

    function renderApp() {
        // *** StudioRouter must be a child of <Router><Switch> <StudioRouter/> </Switch></Router>
        // *** this allows router props (history/location/match ect.,) to be exposed from anywhere in the application
        return (
            <div className='app-container'>
                <Switch>
                    <StudioRouter/>
                </Switch>
                {toaster()}
            </div>
        )
    }

    function StudioRouter(props) {
        return (
            <>
                {syncHelmet(props)}
                {registerRoutes()}
            </>
        )
    }

    function syncHelmet(props) {
        return (
            <>
                <StudioHelmet {...props}/>
                <h1 className='router-header' id='router-header'>
                    {props.location.pathname?.replaceAll('/', ' ').trim()}
                </h1>
            </>
        )
    }

    function renderAppFooter() {
        return (
            <footer className='app-footer'>
                <a className='app-footer-mailto' href='mailto: allen@bluelightstudio.net'>
                    Custom Contract Requests
                </a>
            </footer>
        )
    }
}


export default MainRouter
