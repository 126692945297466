import React from 'react'

import {Link} from 'react-router-dom'

import StudioButton from 'material/buttons/StudioButton'
import StudioForm, {yup} from 'studio/StudioForm'
import StudioInput from 'material/input/StudioInput'
import StudioTitleBar from 'studio/StudioTitleBar'


class SignInForm extends StudioForm {
    constructor(props) {super(props)
        this.state = {
            emailError: false,
            passwordError: false,
        }
    }

    renderChildren(props) {
        return (
            <React.Fragment>
                <StudioInput
                type='email'
                name='email'
                labelText={props.errors?.email ? props.errors.email : 'Email Address'}
                required={true}
                value={props.values?.email && props.values.email}
                error={this.state.emailError}
                onChange={props.handleChange}/>

                <StudioInput
                type='password'
                name='password'
                labelText={props.errors?.password ? props.errors.password : 'Password'}
                required={true}
                value={props.values?.password && props.values.password}
                error={this.state.passwordError}
                onChange={props.handleChange}/>

                <StudioButton
                type='submit'
                text='Submit'
                variant='contained'
                size='large'
                form={this.formID()}/>
                {this.renderResetPasswordLink()}
            </React.Fragment>
        )
    }

    formSchema() {
        return yup.object().shape({
            email: yup.string()
            .max(75, 'Invalid Email Format')
            .email('Invalid Email Format'),

            password: yup.string()
            .min(8, 'Too Short')
            .max(63, 'Too Long'),
        })
    }

    initialValues() {
        return {
            email: '',
            password: '',
        }
    }

    formID() {
        return 'sign-in-form'
    }

    onSubmit(values) {
        this.REST.POST('sign_in_form', values)
        .then(response => this.handleResponse(response, values))
    }

     renderTitleBar() {
        return <StudioTitleBar title='Sign In'/>
    }

    handleResponse(response, values) {
        if (response.data.formik_error) {
            return this.setState({[response.data.formik_error]: true})
        }

        if (response.status === 200) {
            return this.setGlobalSession(response.data.username, values.email)
        }
    }

    setGlobalSession(username, email) {
        return this.stateful({
            user: {
                userName: username,
                userMail: email,
            },
        })
    }

    renderResetPasswordLink() {
        return (
            <div className='reset-password-link'>
                <p>Forgot your password?</p>
                <Link to='/register/email-reset'>
                    Request Password Reset
                </Link>
            </div>
        )
    }
}

export default SignInForm
