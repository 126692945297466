import React from 'react'

import StudioInput from 'material/input/StudioInput'
import StudioButton from 'material/buttons/StudioButton'
import StudioTitleBar from 'studio/StudioTitleBar'
import StudioForm, {yup} from 'studio/StudioForm'


class ConfirmCodeResetForm extends StudioForm {
    constructor() {super()
        this.state = {confirmCodeError: false}
    }

    renderChildren(props) {
        return (
            <React.Fragment>
                <StudioInput
                name='confirmCode'
                labelText={props.errors?.confirmCode ? props.errors.confirmCode : 'Enter Confirmation Code'}
                required={true}
                value={props.values?.confirmCode && props.values.confirmCode}
                error={this.state.confirmCodeError}
                onChange={props.handleChange}/>

                <StudioButton
                type='submit'
                text='Submit'
                variant='contained'
                size='large'
                form={this.formID()}/>
            </React.Fragment>
        )
    }

    formSchema() {
        return yup.object().shape({
            confirmCode: yup.string()
            .min(6, 'Too Short')
            .max(6, 'Too Long'),
        })
    }

    initialValues() {
        return {confirmCode: ''}
    }

    formID() {
        return 'confirm-code-form'
    }

    onSubmit(values) {
        this.REST.POST('confirm_code_reset_form', values)
        .then(response => this.handleResponse(response))
    }

    renderTitleBar() {
        return <StudioTitleBar title='Enter Authorization Code'/>
    }

    handleResponse(response) {
        if (response.data?.formik_error) {
            this.setState({confirmCodeError: true})
        }
    }
}

export default ConfirmCodeResetForm
