import React from 'react'
import Pagination from '@mui/material/Pagination'
import PropTypes from 'prop-types'


let StudioPagerPropTypes = {
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    state: PropTypes.object.isRequired,
}

let StudioPagerDefaultProps = {
    top: false,
    bottom: true,
}


class StudioPager extends React.Component {
    constructor(props) {super(props)
        this.state = {
            ...this.props.state
        }
    }

    componentDidMount() {
        this.props.makeRequest()
    }

    render() {
        return this.renderStudioPager()
    }

    renderStudioPager() {
        return (
            <React.Fragment>
                {this.renderPager('top')}
                {this.props.children}
                {this.props.bottom && this.renderPager('bottom')}
            </React.Fragment>
        )
    }

    renderPager(position) {
        return (
            <div className='pager-wrapper'>
                <Pagination
                className={`pager pager-${position}`}
                page={this.props.state.page}
                count={this.props.state.count}
                onChange={this.props.onChange}/>
            </div>
        )
    }
}


StudioPager.propTypes = StudioPagerPropTypes
StudioPager.defaultProps = StudioPagerDefaultProps


export default StudioPager
