import React from 'react'
import {Route} from 'react-router-dom'

import CartItems from './components/CartItems'
import OrderSummary from './components/OrderSummary'
import ShipRates from './components/ShipRates'
import ShipTo from './components/ShipTo'
import StudioToolbar from 'studio/StudioToolbar'
import StudioButton from 'material/buttons/StudioButton'


const StudioCartRoutes = ['/cart', '/cart/:step']


class StudioCart extends React.Component {
    constructor(props) {super(props)
        this.state = null
    }

    render() {
        return (
            <div>
                <StudioToolbar
                className='studio-toolbar-cart'
                children={this.renderToolbarChildren()}/>

                <Route path='/cart' exact component={CartItems}/>
                <Route path='/cart/order-summary' exact component={OrderSummary}/>
                <Route path='/cart/shipping-rates' exact component={ShipRates}/>
                <Route path='/cart/ship-to' exact component={ShipTo}/>
            </div>
        )
    }

    renderToolbarChildren() {
        return (
            <div className='cart-button-group'>
                <StudioButton
                href='/cart'
                text='Cart Items'
                tooltipTitle='Items currently in your cart.'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'/>

                <StudioButton
                href='/cart/ship-to'
                text='Ship To'
                tooltipTitle='The delivery address for the current order. (required for shipping rates)'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'/>

                <StudioButton
                href='/cart/shipping-rates'
                text='Shipping Rates'
                tooltipTitle='Submit a delivery address using the (Ship To) form, and the shipping options will be shown here.'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'/>

                <StudioButton
                href='/cart/order-summary'
                text='Order Summary'
                tooltipTitle='Finalize the transaction when ready.'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'/>
            </div>
        )
    }
}

StudioCart.routes = StudioCartRoutes


export default StudioCart
