import {getGlobal, setGlobal} from 'reactn'


export function initContext() {
    return JSON.parse(sessionStorage.getItem('context')) || {
        cart: {
            count: 0,
            items: [],
            shipTo: {},
        },
        mode: false,
        user: {
            userName: 'guest',
            userMail: undefined,
        },
    }
}

export function stateful(obj) {
    setGlobal(Object.assign(getGlobal(), obj))

    sessionStorage.setItem('context', JSON.stringify(getGlobal()))
}
