import React from 'react'

import swal from 'sweetalert'

import StudioForm, {yup} from 'studio/StudioForm'
import StudioLoading from 'studio/StudioLoading'
import StudioTitleBar from 'studio/StudioTitleBar'


class FusionForm extends StudioForm {
    constructor(props) {super(props)
        this.state = {
            loading: false,
        }
    }

    renderChildren(props) {
        return this.state.loading ? (
            <StudioLoading mini/>
        ) : (
            <React.Fragment>
                {this.renderFileUpload()}
            </React.Fragment>
        )
    }

    formSchema() {
        return yup.object().shape({
            image: yup.string()
        })
    }

    initialValues() {
        return {
            image: '',
        }
    }

    formID() {
        return 'fusion-form'
    }

    onSubmit(values) {}

     renderTitleBar() {
        return <StudioTitleBar title='Select Background'/>
    }

    renderFileUpload() {
        return (
            <React.Fragment>
                <input
                style={{marginBottom: '8px'}}
                encType='multipart/form-data'
                required
                id='file'
                type='file'
                name='file'
                title=''
                placeholder=''
                onChange={event => this.handleUpload(event)}/>
            </React.Fragment>
        )
    }

    async handleUpload(event) {
        try {
            let reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])

            reader.onload = async read => {
                this.stateful({background_img: read.target.result})
            }

            window.location.reload()
        }
        catch (error) {swal({title: 'Error', text: error})} // intentionally default out
    }
}


export default FusionForm
