import React from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import StudioLoading from './StudioLoading'
import StudioManager from 'rest/StudioManager'


class StudioCollapseTable extends React.Component {
    render() {
        return (
            <div className='studio-collapse-table-wrapper'>
                <div className='studio-collapse-table'>
                    {this.renderTable()}
                </div>
            </div>
        )
    }

    renderTable() {
        return (
            <table className='collapse-table'>
                <tbody className='collapse-table-body'>
                    {this.renderTableHeaders()}
                    {this.props.children?.map((item, index) => {
                        return <CollapsibleRow key={String(index)} {...item}/>
                    })}
                </tbody>
            </table>
        )
    }

    renderTableHeaders() {
        return (
            <tr className='collapse-table-headers'>
                <th scope='col'>Details</th>
                <th scope='col'>User</th>
                <th scope='col'>Email</th>
                <th scope='col'>Date Created</th>
            </tr>
        )
    }
}


export default StudioCollapseTable


class CollapsibleRow extends StudioManager {
    constructor(props) {super(props)
        this.state = {
            open: false,
            order: null,
            loading: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderRow()}
                {this.state.loading ? <StudioLoading mini/> : this.renderCollapseTable()}
            </React.Fragment>
        )
    }

    handleClick() {
        if (!this.state.order) {
            this.setState({loading: true})

            this.REST.GET('order_details', this.props)
            .then(response => {
                this.setState({
                    order: response.data.payload,
                    loading: false,
                })
            })
        }

        return this.setState({open: !this.state.open})
    }

    renderRow() {
        return (
            <tr className='collapsible-row'>
                <td>
                    <button onClick={() => this.handleClick()} className='expand-collapse'>
                        {this.state.open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </button>
                </td>
                <td>{this.props.order_username}</td>
                <td>{this.props.order_email}</td>
                <td>{this.props.date_created}</td>
            </tr>
        )
    }

    renderCollapseTable() {
        return this.state.open && this.state.order && (
            <tr className='order-details-row'>
                <td colSpan={4} className={'order-details-table-wrapper'}>
                    <table className='order-details-table'>
                        <tbody className='order-details-table-body'>
                            {this.renderTableHeaders()}
                            <tr>
                                <td>Purchase Amount</td>
                                <td>{this.state.order.payment.total_money.amount}</td>
                            </tr>
                            <tr>
                                <td>Payment Status</td>
                                <td>{this.state.order.payment.status}</td>
                            </tr>
                            <tr>
                                <td>Receipt URL</td>
                                <td>
                                    <a href={this.state.order.payment.receipt_url}>Receipt Link</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Tracking Number</td>
                                <td>{this.state.order.tracking_number}</td>
                            </tr>
                            <tr>
                                <td>Tracking Status</td>
                                <td>{this.state.order.tracking_status}</td>
                            </tr>
                            <tr>
                                <td>Tracking Provider</td>
                                <td>
                                    <a href={this.state.order.tracking_url_provider}>Provider Link</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        )
    }

    renderTableHeaders() {
        return (
            <tr>
                <th scope='col'>{"Order Details"}</th>
                <th scope='col'>{""}</th>
            </tr>
        )
    }

    renderTable() {}
}
