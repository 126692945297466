import React from 'react'
import PropTypes from 'prop-types'

import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'


let StudioInputPropTypes = {
    // This prop helps users to fill forms faster.
    autoComplete: PropTypes.string,
    // If true, the input element is focused during the first mount.
    autoFocus: PropTypes.bool,
    // The option elements to populate the select with.
    children: PropTypes.node,
    // Override or extend the styles applied to the component.
    // https://mui.com/api/input/#css
    classes: PropTypes.object,
    // className
    className: PropTypes.string,
    // The color of the component.
    // The prop defaults to the value ('primary') inherited from the parent FormControl component.
    color: PropTypes.oneOf(['primary', 'secondary', PropTypes.string]),
    // The components used for each slot inside the InputBase.
    // Either a string to use a HTML element or a component.
    components: PropTypes.elementType,
    // The default value. Use when the component is not controlled.
    defaultValue: PropTypes.any,
    // If true, the component is disabled.
    disabled: PropTypes.bool,
    // End InputAdornment for this component.
    endAdornment: PropTypes.node,
    // If true, the input will indicate an error.
    error: PropTypes.bool,
    // If true, the input will take up the full width of its container.
    fullWidth: PropTypes.bool,
    // The helper text content.
    helperText: PropTypes.node,
    // The id of the input element.
    id: PropTypes.string,
    // The component used for the input element.
    // Either a string to use a HTML element or a component.
    inputComponent: PropTypes.elementType,
    // Attributes applied to the input element.
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes
    inputProps: PropTypes.object,
    // Pass a ref to the input element.
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    // Text to use for Input label
    labelText: PropTypes.string,
    // If dense, will adjust vertical spacing.
    margin: PropTypes.oneOf(['dense', 'none']),
    // Maximum number of rows to display when multiline option is set to true.
    maxRows: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    // Minimum number of rows to display when multiline option is set to true.
    minRows: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    // If true, a textarea element is rendered.
    multiline: PropTypes.bool,
    // Name attribute of the input element.
    name: PropTypes.string,
    // The event source of the callback.
    // You can pull out the new value by accessing event.target.value (string).
    onChange: PropTypes.func,
    // The short hint displayed in the input before the user enters a value.
    placeholder: PropTypes.string,
    // It prevents the user from changing the value of the field (not from interacting with the field).
    readOnly: PropTypes.bool,
    // If true, the input element is required.
    required: PropTypes.bool,
    // Number of rows to display when multiline option is set to true.
    rows: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    // Render a Select element while passing the Input element to Select as input parameter.
    // If this option is set you must pass the options of the select as children.
    select: PropTypes.bool,
    // The size of the component.
    size: PropTypes.oneOf(['medium', 'small', PropTypes.string]),
    // Tooltip text or null will not render
    tooltipTitle: PropTypes.node,
    // Type of the input element. It should be a valid HTML5 input type.
    type: PropTypes.string,
    // The value of the input element, required for a controlled component.
    value: PropTypes.any,
    // The variant to use.
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),

    // Tooltip props
    tooltipPlacement: PropTypes.string,
    // Tooltip props
    tooltipDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

let StudioInputDefaultProps = {
    autoComplete: 'on',
    autoFocus: false,
    children: null,
    classes: null,
    className: 'studio-input',
    color: null,
    components: null,
    defaultValue: undefined,
    disabled: false,
    error: false,
    fullWidth: true,
    helperText: null,
    labelText: null,
    margin: 'dense',
    name: null,
    onChange: ()=>{},
    placeholder: null,
    readOnly: false,
    required: false,
    select: false,
    size: 'small',
    tooltipTitle: null,
    type: 'text',
    value: null,
    variant: 'outlined',

    // Tooltip props
    tooltipPlacement: 'top',
    // Tooltip props
    tooltipDelay: 750,
}


class StudioInput extends React.Component {
    constructor(props) {super(props)
        this.state = {
            showPassword: false,
            type: this.props.type,
        }
    }

    render() {
        return !this.props.disabled ? (
            this.renderInput()
        ) : this.renderTextRepresentation()
    }

    renderInput() {
        return (
            <Tooltip
            className='studio-tooltip'
            title={this.props.tooltipTitle}
            placement={this.props.tooltipPlacement}
            enterDelay={Number(this.props.tooltipDelay)}>
                {this.renderTextField()}
            </Tooltip>
        )
    }

    renderTextRepresentation() {
        let hidden = this.props.value === '' ? true : false

        return (
            <h5 style={hidden ? { display: 'none' } : null}>
                {this.props.value}
            </h5>
        )
    }

    renderTextField() {
        return (
            <TextField
            InputProps={this.inputProps()}

            autoComplete={this.props.autoComplete}
            autoFocus={this.props.autoFocus}
            children={this.props.children}
            className={this.props.className}
            classes={this.props.classes}
            color={this.props.color}
            components={this.props.components}
            defaultValue={this.props.defaultValue}
            error={this.props.error}
            fullWidth={this.props.fullWidth}
            helperText={this.props.helperText}
            inputProps={this.props.inputProps}
            label={this.props.labelText}
            margin={this.props.margin}
            name={this.props.name}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            readOnly={this.props.readOnly}
            required={this.props.required}
            select={this.props.select}
            size={this.props.size}
            type={this.state.type}  // state managed when needed
            value={this.props.value}
            variant={this.props.variant}/>
        )
    }

    inputProps() {
        return this.props.type === 'password' ? ({
            endAdornment:
            <InputAdornment className='toggle-password-visible' position='end'>
                {this.state.showPassword ? (
                    <VisibilityOffRoundedIcon
                    onClick={() => this.togglePasswordVisible()}/>
                ) : <VisibilityRoundedIcon
                    onClick={() => this.togglePasswordVisible()}/>}
            </InputAdornment>
        }) : null
    }

    togglePasswordVisible() {
        this.setState({showPassword: !this.state.showPassword})

        this.state.showPassword ? (
            this.setState({type: 'password'})
        ) : this.setState({type: 'text'})
    }
}


StudioInput.propTypes = StudioInputPropTypes
StudioInput.defaultProps = StudioInputDefaultProps


export default StudioInput
