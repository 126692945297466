import React from 'react'
// import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import StudioInput from 'material/input/StudioInput'
import StudioManager from 'rest/StudioManager'


let CollectionFiltersPropTypes = {}

let CollectionFiltersDefaultProps = {}


class CollectionFilters extends StudioManager {
    constructor(props) {super(props)
        this.state = this.props.status()
        this.debounceFilteredRequest = debounce(this.makeRequest, 750)
    }

    componentDidUpdate(prevProps) {
        // sync status with parent
        if (prevProps.status !== this.props.status) {
            this.setState(this.props.status())
        }
    }

    render() {
        return this.renderFilters()
    }

    renderFilters() {
        return (
            <div className='filters-container'>
                {this.renderFilterForSaleSwitch()}
                {this.renderFilterByTitle()}
                {this.renderFilterByFileName()}
                {this.renderFilterByFeaturedSwitch()}
            </div>
        )
    }

    async filterRequest(filterType, event) {
        // await for state updates to apply. then request.
        if (filterType === 'filterByForSale' || filterType === 'filterByFeatured') {
            // don't debounce switches
            await this.props.setFilters({filterType: filterType, value: !this.state[filterType]})
            return this.makeRequest()
        }
        else {
            // debounce keystroke inputs
            await this.props.setFilters({filterType: filterType, value: event.target.value})
            return this.debounceFilteredRequest()
        }
    }

    makeRequest() {
        let payload = {...this.state}  // shallow copy state
        delete payload.items
        payload.collection = this.props.collection
        this.REST.GET('studio_collection', payload)
        .then(response => this.props.setItems(response.data.payload))
    }

    renderFilterForSaleSwitch() {
        return (
            <FormControlLabel
            className='studio-form-control-label'
            label='Filter by Items for Sale'
            labelPlacement='start'
            control={
                <Switch
                className='studio-input-switch'
                onChange={event => this.filterRequest('filterByForSale', event)}/>
            }/>
        )
    }

    renderFilterByTitle() {
        return (
            <StudioInput
            type='text'
            name='filter_by_title'
            labelText='Filter by Item Title'
            fullWidth={false}
            value={this.state.filterByTitle}
            onChange={event => this.filterRequest('filterByTitle', event)}/>
        )
    }

    renderFilterByFileName() {
        return this.SESSION.mode ? (
            <StudioInput
            type='text'
            name='filter_by_filename'
            labelText='Filter by File Name'
            fullWidth={false}
            value={this.state.filterByFileName}
            onChange={event => this.filterRequest('filterByFileName', event)}/>
        ) : null
    }

    renderFilterByFeaturedSwitch() {
        return (
            <FormControlLabel
            className='studio-form-control-label'
            label='Filter by Featured Items'
            labelPlacement='start'
            control={
                <Switch
                className='studio-input-switch'
                onChange={event => this.filterRequest('filterByFeatured', event)}/>
            }/>
        )
    }
}


CollectionFilters.propTypes = CollectionFiltersPropTypes
CollectionFilters.defaultProps = CollectionFiltersDefaultProps


export default CollectionFilters
