import React from 'react'
import {Route} from 'react-router-dom'

import OrderHistoryList from './components/OrderHistoryList'


const OrdersRoutes = ['/orders',]


class Orders extends React.Component {
    constructor(props) {super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <Route path='/orders' exact component={OrderHistoryList}/>
            </React.Fragment>
        )
    }
}

Orders.routes = OrdersRoutes


export default Orders
