import React from 'react'

import StudioButton from 'material/buttons/StudioButton'
import StudioForm, {yup} from 'studio/StudioForm'
import StudioInput from 'material/input/StudioInput'
import StudioTitleBar from 'studio/StudioTitleBar'


class UpdatePasswordForm extends StudioForm {
    constructor() {super()
        this.state = {
            passwordError: false,
            confirmPasswordError: false,
        }
    }

    renderChildren(props) {
        return (
            <React.Fragment>
                <StudioInput
                type='password'
                name='password'
                labelText={props.errors?.password ? props.errors.password : 'New Password'}
                required={true}
                value={props.values?.password && props.values.password}
                error={this.state.passwordError}
                onChange={props.handleChange}/>

                <StudioInput
                type='password'
                name='confirmPassword'
                labelText={props.errors?.confirmPassword ? props.errors.confirmPassword : 'Confirm Password'}
                required={true}
                value={props.values?.confirmPassword && props.values.confirmPassword}
                error={this.state.confirmPasswordError}
                onChange={props.handleChange}/>

                <StudioButton
                type='submit'
                text='Submit'
                variant='contained'
                size='large'
                form={this.formID()}/>
            </React.Fragment>
        )
    }

    initialValues() {
        return {
            password: '',
            confirmPassword: '',
        }
    }

    formID() {
        return 'update-password-form'
    }

    onSubmit(values) {
        values.password === values.confirmPassword ? (
            this.REST.POST('update_password_form', values)
        ) : this.setState({confirmPasswordError: true})
    }

     renderTitleBar() {
        return <StudioTitleBar title='Reset Password'/>
    }

    formSchema() {
        return yup.object().shape({
            password: yup.string()
            .min(8, 'Too Short')
            .max(63, 'Too Long'),

            confirmPassword: yup.string()
            .min(8, 'Too Short')
            .max(63, 'Too Long')
            .oneOf([yup.ref('password'), null], 'Passwords Do Not Match'),
        })
    }
}

export default UpdatePasswordForm
