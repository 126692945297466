import StudioButton from 'material/buttons/StudioButton'
import StudioForm, {yup} from 'studio/StudioForm'
import StudioLoading from 'studio/StudioLoading'
import StudioTitleBar from 'studio/StudioTitleBar'


class GenerateThumbs extends StudioForm {
    constructor(props) {super(props)
        this.state = {loading: false}
    }

    renderChildren() {
        return (
            <>
                <h5>Generate Thumbnail versions of StudioItem images.</h5>
                <StudioButton
                type='submit'
                text='Generate Thumbs'
                variant='contained'
                size='large'/>
            </>
        )
    }

    formSchema() {
        return yup.object().shape({
            emptyString: yup.string()
        })
    }

    initialValues() {
        return {
            emptyString: '',
        }
    }

    formID() {
        return 'generate-thumbs-form'
    }

    onSubmit() {
        this.setState({loading: true})

        this.REST.POST('generate_thumbs')
        .then(() => this.setState({loading: false}))
    }

     renderTitleBar() {
        return (
            <>
                {this.state.loading && <StudioLoading/>}
                <StudioTitleBar title='Generate Thumbs'/>
            </>
        )
    }
}

export default GenerateThumbs
