import React from 'react'
import {Rnd} from 'react-rnd'

import FusionForm from 'app/boss/components/FusionForm'
import {saveAs} from 'file-saver'
import {sparrow, song} from 'util/sparrow/Sparrow'
import StudioButton from 'material/buttons/StudioButton'
import StudioManager from 'rest/StudioManager'


let FusionPropTypes = {}

let FusionDefaultProps = {}


class Fusion extends StudioManager {
    constructor(props) {super(props)
        this.state={
            foreground_height: 0,
            foreground_width: 0,
            background_height: 0,
            background_width: 0,
            combined_image: null,
        }

        this.background_img = this.SESSION.background_img || null
        this.foreground_img = this.SESSION.foreground_img || null
    }

    async componentDidMount() {
        if (this.foreground_img) {
            let foreground = await this.getImageDimensions(this.foreground_img)

            this.setState({
                foreground_height: foreground.height,
                foreground_width: foreground.width,
            })

            let canvas = document.getElementById('RnD-canvas')

            if (canvas) {
                let ctx = canvas.getContext('2d')

                canvas.width = foreground.width
                canvas.height = foreground.height

                ctx.drawImage(foreground.image, 0, 0, foreground.width, foreground.height)

                document.getElementById('RnD').style.border = `solid 1px #000`
            }
        }

        if (this.background_img) {
            let background = await this.getImageDimensions(this.background_img)

            this.setState({
                background_height: background.height,
                background_width: background.width,
            })

            let canvas = document.getElementById('Canvas')
            let ctx = canvas.getContext('2d')

            canvas.width = background.width
            canvas.height = background.height

            ctx.drawImage(background.image, 0, 0, window.innerWidth, window.innerHeight)
        }
    }

    render() {
        return (
            <div className='fusion-wrapper'>
                <div className='fusion-container'>

                    {this.foreground_img && this.background_img ? (
                        this.renderFusionButtons()
                    ) : null}

                    {!this.foreground_img ? (
                        this.renderNoForegroundImage()
                    ) : !this.background_img ? (
                        this.renderFusionForm()
                    ) : this.renderCanvas()}

                </div>
            </div>
        )
    }

    renderFusionButtons() {
        return (
            <React.Fragment>
                <StudioButton
                className='fusion-button-clear'
                text='Clear'
                tooltipTitle='Clear the canvas.'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'
                onClick={() => this.clearCanvas()}/>

                <StudioButton
                className='fusion-button-fuse'
                text='Fuse'
                tooltipTitle='Combine the foreground and background images.'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'
                onClick={() => this.mergeImages()}/>

                <StudioButton
                className='fusion-button-save'
                text='Save'
                tooltipTitle='Download the fused image.'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'
                onClick={() => this.saveImage()}/>
            </React.Fragment>
        )
    }

    renderFusionForm() {
        return <FusionForm/>
    }

    renderCanvas() {
        return (
            <div id='Canvas-container'>
                <canvas id='Canvas'/>
                {this.renderRnD()}
            </div>
        )
    }

    renderRnD() {
        const style = {
            display: 'flex',
            justifyContent: 'flexStart',
            border: 'none',
            background: 'transparent',
            overflow: 'hidden', //RnD scrollbar
            zIndex: '1',
        }

        return (
            <Rnd
            id='RnD'
            style={style}
            // lockAspectRatio={true}
            default={{
                x: 50,
                y: 75,
                width: this.state.foreground_width || 150,
                height: this.state.foreground_height || 150,
            }}>
                <canvas id='RnD-canvas' value=''/>
            </Rnd>
        )
    }

    renderNoForegroundImage() {
        return (
            <p className='no-foreground-image'>
                Please use Bleach BG to create a foreground image before using Fusion.
            </p>
        )
    }

    getImageDimensions(file) {
        return new Promise(function (resolved, rejected) {
            var img = new Image()
            img.src = file
            img.onload = function() {
                resolved({ width: img.width, height: img.height, image: img})
            }
        })
    }

    clearCanvas() {
        this.stateful({foreground_img: null, background_img: null})
        this.background_img = null
        this.foreground_img = null

        window.location.reload()
    }

    mergeImages() {
        let nav = document.getElementsByClassName('navbar')[0]
        let toolbar = document.getElementsByClassName('studio-toolbar')[0]
        let overlay = document.getElementById('RnD-canvas')

        let w = this.state.foreground_width
        let h = this.state.foreground_height

        let rndContext = overlay.getContext('2d')

        function stepDown() {
            if (w / 2 > overlay.clientWidth || h / 2 > overlay.clientHeight) {
                w = Math.ceil(w / 2)
                h = Math.ceil(h / 2)

                let tempCanvas = document.createElement('canvas')
                tempCanvas.width = w
                tempCanvas.height = h
                let tempContext = tempCanvas.getContext('2d')

                tempContext.drawImage(overlay, 0, 0, w, h,)
                rndContext.clearRect(0, 0, overlay.width, overlay.height)
                rndContext.drawImage(tempCanvas, 0, 0, overlay.width, overlay.height,)

                if (w / 2 > overlay.clientWidth || h / 2 > overlay.clientHeight) {
                    stepDown()
                }
            }
        }

        stepDown()

        let underlay = document.getElementById('Canvas')
        let ctx = underlay.getContext('2d')

        ctx.imageSmoothingEnabled = false
        ctx.webkitImageSmoothingEnabled = false
        ctx.msImageSmoothingEnabled = false

        let overX = window.scrollX + overlay.getBoundingClientRect().x
        let underX = window.scrollX + underlay.getBoundingClientRect().x

        let dropX = (overX - (this.state.background_width - underlay.clientWidth) + underX)
        let dropY = ((window.scrollY + overlay.getBoundingClientRect().y) - (nav.clientHeight + toolbar.clientHeight))

        ctx.drawImage(overlay, Math.floor(dropX), Math.floor(dropY), overlay.clientWidth, overlay.clientHeight)

        const imgURL = underlay.toDataURL('image/jpeg', 1.0)

        this.setState({combined_image: imgURL})

        rndContext.clearRect(0, 0, overlay.width, overlay.height)

        document.getElementById('RnD').style.border = 'none' //visualization
    }

    saveImage() {
        if (!this.state.combined_image) {
            return sparrow({option: song.FUSE_FIRST})
        }

        saveAs(this.state.combined_image, 'Fusion Concept.jpg')
    }
}


Fusion.propTypes = FusionPropTypes
Fusion.defaultProps = FusionDefaultProps


export default Fusion
