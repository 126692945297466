import React from 'react'

import {saveAs} from 'file-saver'
import swal from 'sweetalert'

import StudioButton from 'material/buttons/StudioButton'
import StudioForm, {yup} from 'studio/StudioForm'
import StudioLoading from 'studio/StudioLoading'
import StudioTitleBar from 'studio/StudioTitleBar'


class BleachBGForm extends StudioForm {
    constructor(props) {super(props)
        this.state = {
            img_64: this.SESSION.foreground_img || null,
            img_file_name: null,
            preview_image: this.SESSION.foreground_img || null,
            loading: false,
        }
    }

    renderChildren(props) {
        return this.state.loading ? (
            <StudioLoading mini/>
        ) : (
            <React.Fragment>
                {this.state.img_64 ? (
                    this.renderButtons()
                ) : null}

                {this.renderFileUpload()}

                <StudioButton
                type='submit'
                text='Remove Background'
                variant='contained'
                size='large'
                form={this.formID()}/>

                {this.renderPreviewImage()}
            </React.Fragment>
        )
    }

    formSchema() {
        return yup.object().shape({
            image: yup.string()
        })
    }

    initialValues() {
        return {
            image: '',
        }
    }

    formID() {
        return 'bleach-bg-form'
    }

    onSubmit(values) {
        this.setState({loading: true})

        this.REST.POST('bleach_bg', this.state)
        .then(response => this.handleResponse(response, values))
    }

     renderTitleBar() {
        return <StudioTitleBar title='Bleach Background'/>
    }

    handleResponse(response, values) {
        if (response?.data?.payload) {
            this.setState({preview_image: response.data.payload})
            this.stateful({foreground_img: response.data.payload})
        }

        this.setState({loading: false})
    }

    renderFileUpload() {
        return (
            <React.Fragment>
                <input
                style={{marginBottom: '8px'}}
                encType='multipart/form-data'
                required
                id='file'
                type='file'
                name='file'
                title=''
                placeholder=''
                onChange={event => this.handleUpload(event)}/>
            </React.Fragment>
        )
    }

    async handleUpload(event) {
        try {
            this.setState({img_file_name: event.target.files[0].name})

            let reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])

            reader.onload = async read => {
                this.setState({img_64: read.target.result})
            }
        }
        catch (error) {swal({title: 'Error', text: error})} // intentionally default out
    }

    renderPreviewImage() {
        return this.state.preview_image ? (
            <img src={this.state.preview_image} alt={this.state.img_file_name}/>
        ) : null
    }

    renderButtons() {
        return (
            <React.Fragment>
                <StudioButton
                className='bleach-button-clear'
                text='Clear'
                tooltipTitle='Clear the canvas.'
                tooltipPlacement='bottom'
                variant='text'
                color='secondary'
                onClick={() => this.clearCanvas()}/>

                {this.state.preview_image ? (
                    <StudioButton
                    className='bleach-button-save'
                    text='Save'
                    tooltipTitle='Download the enhanced image.'
                    tooltipPlacement='bottom'
                    variant='text'
                    color='secondary'
                    onClick={() => this.saveImage()}/>
                ) : null}
            </React.Fragment>
        )
    }

    clearCanvas() {
        this.stateful({foreground_img: null})
        this.setState({img_64: null, preview_image: null})

        window.location.reload()
    }

    saveImage() {
        saveAs(this.state.preview_image, 'Foreground Concept.png')
    }
}


export default BleachBGForm
