import React from 'react'

import {formatter} from 'util/CurrencyFormatter'
import {scrubShippingRates} from 'app/cart/utils/CartManage'
import {SquarePaymentsForm, CreditCardInput} from 'react-square-web-payments-sdk'
import STUDIO_ENV from 'studioEnvironment'
import StudioTitleBar from 'studio/StudioTitleBar'
import StudioLoading from 'studio/StudioLoading'
import StudioManager from 'rest/StudioManager'


class OrderSummary extends StudioManager {
    constructor(props) {super(props)
        this.state={loading: false}
        this.cartItems = this.SESSION.cart.items
        this.shippingFee = this.SESSION.cart.selectedShippingRate?.amount
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <StudioLoading/>}

                <div className='order-summary-wrapper'>
                    {this.renderTitleBar()}

                    <div className='order-summary'>
                        {this.renderItemsTotalCost()}
                        {this.renderTaxDetails()}
                        {this.renderShippingDetails()}
                        {this.renderOrderTotalAmount()}
                    </div>

                    <div className='payment-form'>
                        {this.renderPaymentForm()}
                    </div>

                    {this.mockPaymentForm()}
                </div>
            </React.Fragment>
        )
    }

    handleTokenizeResponse(response) {
        if (response.status === 'OK') {
            this.setState({loading: true})

            this.REST.POST('purchase_order', this.buildTransactionData(response))
            .then(res => {
                this.setState({loading: false})
                if (res?.status === 201) {
                    scrubShippingRates()
                    this.stateful({cart: {count: 0, items: [],}})
                    return
                }
            })
        }
    }

    buildTransactionData(response) {
        // get a shallow copy and trim any unneeded data
        let data = {...this.SESSION}
        delete data.collection
        delete data.mode
        delete data.section
        data.cart.items.forEach(item => {delete item.img_64})

        // append the total purchase amount
        data.purchaseAmount = this.getTotalPurchaseAmount()

        // merge data objects
        data = Object.assign(data, response)

        return data
    }

    renderTitleBar() {
        return <StudioTitleBar title='Order Summary'/>
    }

    renderPaymentForm() {
        return (
            <SquarePaymentsForm
            applicationId={STUDIO_ENV.REACT_APP_SQUARE_ID}
            locationId='default'
            cardTokenizeResponseReceived={response => this.handleTokenizeResponse(response)}>
                <CreditCardInput text={`Pay Now: ${formatter.format(this.getTotalPurchaseAmount())}`}/>
            </SquarePaymentsForm>
        )
    }

    renderOrderColumns(left, right) {
        return (
            <div>
                <h5>{left}</h5>
                <h5>{right}</h5>
            </div>
        )
    }

    renderItemsTotalCost() {
        return (
            this.renderOrderColumns('Items Total Cost:', formatter.format(this.getItemTotalBeforeTax()))
        )
    }

    renderShippingDetails() {
        return this.shippingFee ? (
            this.renderOrderColumns('Shipping Cost:', formatter.format(this.shippingFee))
        ) : this.renderOrderColumns('Shipping:', 'N/A')
    }

    renderOrderTotalAmount() {
        return (
            this.renderOrderColumns('Total Order Amount', formatter.format(this.getTotalPurchaseAmount()))
        )
    }

    renderTaxDetails() {
        return (
            this.renderOrderColumns(
                'Sales Tax (if applicable):',
                this.shouldAddSalesTax() ? (
                    formatter.format(this.calculateSalesTax())
                ) : formatter.format(0)
            )
        )
    }

    getTotalPurchaseAmount() {
        let shippingFee = +this.shippingFee || +0

        if (this.shouldAddSalesTax()) {
            return (
                this.getItemTotalBeforeTax() + this.calculateSalesTax() + shippingFee
            )
        }

        return (this.getItemTotalBeforeTax() + shippingFee)
    }

    getItemTotalBeforeTax() {
        let total = 0.0

        this.cartItems.forEach(element => {
            total += Number(element.price)
        })

        return Number(total)
    }

    calculateSalesTax() {
        let tax = ((this.getItemTotalBeforeTax()) * 9.25 / 100).toFixed(2)
        return Number(tax)
    }

    shouldAddSalesTax() {
        if (!this.SESSION.cart.shipTo?.state) {
            return true
        }

        let shipToState = this.SESSION.cart.shipTo.state

        if (shipToState.toLowerCase() === 'tn') {
            return true
        }

        return false
    }

    /////////////////////////////////////////////////////
    // mock squareup payment with jwt credentials enabled
    // should only render in development
    mockPaymentForm() {
        let data = {
            'details': {
                'card': {
                    'brand': 'VISA',
                    'expMonth': 1,
                    'expYear': 2028,
                    'last4': '1111',
                },
                'method': 'Card',
                'billing': {
                    'postalCode': '37419',
                },
            },
            'status': 'OK',
            'token': 'cnon:card-nonce-ok',
        }

        return !STUDIO_ENV.IS_PRODUCTION && (
            <button onClick={() => {this.handleTokenizeResponse(data)}}>
                Mock Payment
            </button>
        )
    }
}


export default OrderSummary
