import Grinder from 'assets/Grinder.jpg'
import LandingVideo from 'assets/LandingVideo.mp4'
import React from 'react'


class About extends React.Component {
    render() {
        return (
            <div className='about-wrapper'>
                <div className='about'>
                    {this.renderBioPicRow()}
                    {this.renderVideoRow()}
                </div>
            </div>
        )
    }

    renderBioPic() {
        return <img src={Grinder} alt='grinder'/>
    }

    renderVideo() {
        return (
            <video
            className='video'
            preload='metadata'
            // autoPlay
            controls
            muted
            loop={false}
            src={LandingVideo}/>
        )
    }

    renderBioPicRow() {
        return (
            <div className='about-row even'>
                {this.renderBioPic()}
                {this.renderAboutTheArtist()}
            </div>
        )
    }

    renderVideoRow() {
        return (
            <div className='about-row odd'>
                {this.renderVideo()}
            </div>
        )
    }

    renderAboutTheArtist() {
        return (
            <div className='about-the-artist'>
                <p>
                    I graduated from the University of Tennessee in 1980 with a degree in Architecture. After I graduated I
                    went hitch hiking around Europe for a year. That experience had a profound impact on me. When I
                    returned, I went to work for an Architect in Memphis. I found that I had a hard time sitting behind a
                    desk all day after vagabonding for so long. While at the firm I discovered a potter’s guild that would
                    share their equipment for a monthly fee. I began working there nights and on the weekends. After a few
                    months the project I was working on in the firm was delayed and I had to be laid off. It was then, in
                    1982, I decided I would see if I could make a living as an artist.
                </p>
                <p>
                    I was able to spend the first couple of years working out of my grandfathers house and garage. He died
                    and I was forced to find my own place. I found a little piece of land on a mountain stream in the
                    Cherokee National Forest area and built a home and studio myself from the ground up. I met my wife
                    and her daughter in 1982 and we lived on the property for 25 years. I was working in clay during most
                    of that time. I started working in metal in 1998 after taking a welding class at a vocational school.
                </p>
                <p>
                    The 2008 crash forced us to sell our home out in the wilderness because of little opportunities for
                    income. We ended up in Chattanooga Tn. in a house that was built in 1947 which we totally renovated.
                </p>
                <p>
                    I stopped working in clay around 2011 to focus on metal work. I love experimenting with new ideas.
                    When experimenting I try not to judge while I am making something. I allow my intuition to guide me
                    which often gives me unexpected results. For me, the process of making something is the most
                    interesting and when I am finished, I am ready to move on to the next one.
                </p>
                <p>
                    I like doing commission work. Commissions force me to make something that I wouldn’t normally do.
                    I try to balance the customers needs with my own ideas. I always learn from those experiences. I feel
                    very lucky to be making a living from my artwork for all of these years. I am always looking forward
                    to the next creation.
                </p>
                <p className='signed'>
                    Allen Hampton
                </p>
            </div>
        )
    }
}


export default About
