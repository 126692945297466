import React from 'react'

import FormControl from '@mui/material/FormControl'
import {Formik} from 'formik'
import MenuItem from '@mui/material/MenuItem'
import StudioButton from 'material/buttons/StudioButton'
import StudioInput from 'material/input/StudioInput'
import StudioItem from 'studio/StudioItem'
import StudioManager from 'rest/StudioManager'
import StudioTitleBar from 'studio/StudioTitleBar'
import swal from 'sweetalert'


class NewStudioItem extends StudioManager {
    constructor() {super()
        this.state = {
            section: null,
            collection: null,
            img_file_name: '',
            img_64: null,
            price: null,
            width: null,
            height: null,
            depth: null,
            weight: null,
            title: null,
            description: null,
            is_premier_item: false,
            is_featured_item: false,
            collectionsList: null,
        }
    }

    render() {
        return (
            <React.Fragment>
            <Formik
            initialValues={this.initialValues()}
            onSubmit={async values => this.onSubmit(values)}>

                {props => (
                    <div className='studio-form'>
                        <form onSubmit={props.handleSubmit} id='new-studio-item-form' style={{maxWidth: '30em'}}>
                            {this.renderTitleBar()}
                            <FormControl className='studio-form-control'>
                                {this.renderFileUpload()}
                                {this.renderSectionInput(props)}
                                {this.state.section &&
                                    <React.Fragment>
                                        {this.renderCollectionInput(props)}
                                        {this.renderInputs(props)}

                                        <StudioButton
                                        type='submit'
                                        text='Save New Item'
                                        variant='contained'
                                        size='large'
                                        form='new-studio-item-form'/>
                                    </React.Fragment>}
                            </FormControl>
                        </form>
                    </div>
                )}

            </Formik>

            {this.renderPreviewItem()}
            </React.Fragment>
        )
    }

    initialValues() {
        return {
            section: '',
            collection: '',
            img_64: '',
            title: '',
            price: '',
            width: '',
            height: '',
            depth: '',
            weight: '',
            img_file_name: '',
            description: '',
            is_premier_item: false,
            is_featured_item: false,
        }
    }

    onSectionChange(event) {
        this.setState({section: event.target.value})
        return this.getCollectionOptionsList(event.target.value)
    }

    renderTitleBar() {
        return <StudioTitleBar title='New Studio Item'/>
    }

    renderSectionOptions() {
        let options = [
            {display_value: 'Wall', value: 'wall'},
            {display_value: 'Decor & Installations', value: 'decor'},
            {display_value: 'Sculptures', value: 'sculptures'},
            {display_value: 'Signs', value: 'signs'},
        ]

        return (
            options.map(item => (
                <MenuItem value={item.value} key={item.value}>
                    {item.display_value}
                </MenuItem>
            ))
        )
    }

    renderSectionInput(props) {
        return (
            <StudioInput
            name='section'
            select={true}
            children={this.renderSectionOptions()}
            labelText='Section'
            required={true}
            size='small'
            margin='dense'
            error={props.errors.section}
            onChange={event => this.onSectionChange(event)}
            value={this.state.section || ''}/>
        )
    }

    renderCollectionOptions() {
        let menuItems = []

        if (this.state.collectionsList) {
            menuItems = this.state.collectionsList.map(item => (
                <MenuItem value={item} key={item}>
                    {item}
                </MenuItem>
            ))
        }
        return menuItems
    }

    renderCollectionInput(props) {
        return (
            <StudioInput
            name='collection'
            select={true}
            children={this.renderCollectionOptions()}
            labelText='Collection'
            required={true}
            value={props.values.collection}
            error={props.errors.collection}
            inputProps={{onChange: props.handleChange}}
            onChange={event => this.setState({collection: event.target.value})}/>
        )
    }

    renderInputs(props) {
        return (
            <React.Fragment>
                <StudioInput
                type='text'
                name='title'
                labelText='Title'
                value={props.values.title}
                error={props.errors.title}
                inputProps={{onChange: props.handleChange}}
                onChange={event => this.setState({title: event.target.value})}/>

                <StudioInput
                type='text'
                name='price'
                labelText='Price'
                value={props.values.price}
                error={props.errors.price}
                inputProps={{onChange: props.handleChange}}
                onChange={event => this.setState({price: event.target.value})}/>


                <StudioInput
                type='text'
                name='width'
                labelText='Width'
                value={props.values.width}
                error={props.errors.width}
                inputProps={{onChange: props.handleChange}}
                onChange={event => this.setState({width: event.target.value})}/>

                <StudioInput
                type='text'
                name='height'
                labelText='Height'
                value={props.values.height}
                error={props.errors.height}
                inputProps={{onChange: props.handleChange}}
                onChange={event => this.setState({height: event.target.value})}/>

                <StudioInput
                type='text'
                name='depth'
                labelText='Depth'
                value={props.values.depth}
                error={props.errors.depth}
                inputProps={{onChange: props.handleChange}}
                onChange={event => this.setState({depth: event.target.value})}/>

                <StudioInput
                type='text'
                name='weight'
                labelText='Weight'
                value={props.values.weight}
                error={props.errors.weight}
                inputProps={{onChange: props.handleChange}}
                onChange={event => this.setState({weight: event.target.value})}/>

                <StudioInput
                type='text'
                name='description'
                labelText='Description'
                value={props.values.description}
                error={props.errors.description}
                inputProps={{onChange: props.handleChange}}
                onChange={event => this.setState({description: event.target.value})}/>
            </React.Fragment>
        )
    }

    renderPreviewItem() {
        return (
            <div style={{maxWidth: '30em', margin: '25px auto'}}>
                <StudioItem {...this.state} isPreview={true}/>
            </div>
        )
    }

    renderFileUpload() {
        return (
            <React.Fragment>
                <input
                style={{marginBottom: '10px'}}
                encType='multipart/form-data'
                required
                id='file'
                type='file'
                name='file'
                title=''
                placeholder=''
                onChange={event => this.handleUpload(event)}/>
            </React.Fragment>
        )
    }

    async handleUpload(event) {
        try {
            this.setState({img_file_name: event.target.files[0].name})

            let reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])

            reader.onload = async read => {
                this.setState({img_64: read.target.result})
            }
        }
        catch (error) {swal({title: 'Error', text: error})} // intentionally default out
    }

    getCollectionOptionsList(value) {
        return (
            this.REST.GET('collection_options_list', {payload: value})
            .then(response => {
                this.setState({collectionsList: response.data.payload})
            })
        )
    }

    onSubmit() {
        // get a shallow copy of state
        let payload = {...this.state}
        // must mirror StudioItem model
        delete payload.collectionsList
        this.REST.POST('new_studio_item', payload)
    }
}


export default NewStudioItem
