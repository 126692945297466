import React from 'react'

import {Formik} from 'formik'
import FormControl from '@mui/material/FormControl'
import {renderUnitedStatesSelector} from 'util/SelectorHelpers'
import {scrubShippingRates} from 'app/cart/utils/CartManage'
import StudioButton from 'material/buttons/StudioButton'
import StudioInput from 'material/input/StudioInput'
import StudioLoading from 'studio/StudioLoading'
import StudioManager from 'rest/StudioManager'
import StudioTitleBar from 'studio/StudioTitleBar'


export default class ShipTo extends StudioManager {
    constructor(props) {super(props)
        this.state = this.SESSION.cart.shipTo || {
            name: '',
            street1: '',
            unit: '',
            city: '',
            state: '',
            zip: '',
            loading: false,
        }
        this.items = this.SESSION.cart.items
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <StudioLoading/>}
                {this.renderShipToForm()}
            </React.Fragment>
        )
    }

    renderShipToForm() {
        return (
            <Formik
            initialValues={this.initialValues()}
            onSubmit={async values => this.onSubmit(values)}>

                {props => (
                    <div className='studio-form'>
                        <form onSubmit={props.handleSubmit} id='ship-to-address-form'>

                            <StudioTitleBar title='Ship To Address'/>

                            <FormControl>

                                <StudioInput
                                name='name'
                                labelText='Name'
                                required={true}
                                size='small'
                                margin='dense'
                                error={props.errors.name}
                                onChange={event => this.setState({name: event.target.value})}
                                value={this.state.name || ''}/>

                                <StudioInput
                                name='street address'
                                labelText='Street Address'
                                required={true}
                                size='small'
                                margin='dense'
                                error={props.errors.street1}
                                onChange={event => this.setState({street1: event.target.value})}
                                value={this.state.street1 || ''}/>

                                <StudioInput
                                name='unit #'
                                labelText='Unit #'
                                required={false}
                                size='small'
                                margin='dense'
                                error={props.errors.unit}
                                onChange={event => this.setState({unit: event.target.value})}
                                value={this.state.unit || ''}/>

                                <StudioInput
                                name='city'
                                labelText='City'
                                required={true}
                                size='small'
                                margin='dense'
                                error={props.errors.city}
                                onChange={event => this.setState({city: event.target.value})}
                                value={this.state.city || ''}/>

                                <StudioInput
                                name='state'
                                select={true}
                                children={renderUnitedStatesSelector()}
                                labelText='State'
                                required={true}
                                size='small'
                                margin='dense'
                                error={props.errors.state}
                                onChange={event => this.setState({state: event.target.value})}
                                value={this.state.state || ''}/>

                                <StudioInput
                                name='zip code'
                                labelText='Zip Code'
                                required={true}
                                size='small'
                                margin='dense'
                                error={props.errors.zip}
                                onChange={event => this.setState({zip: event.target.value})}
                                value={this.state.zip || ''}/>

                                <div className='info-text'>
                                    <p>Submit for Shipping Options</p>
                                </div>

                                <StudioButton
                                type='submit'
                                text='Submit'
                                form='ship-to-address-form'
                                variant='contained'
                                size='large'/>

                                <div className='info-text'>
                                    <p>Or Proceed to Checkout</p>
                                    <p>(Local Delivery/Pick-up)</p>
                                </div>

                                <StudioButton
                                text='Skip To Checkout'
                                variant='contained'
                                size='large'
                                onClick={() => {this.handleSkipShippingRates()}}/>

                            </FormControl>

                        </form>
                    </div>
                )}

            </Formik>
        )
    }

    handleSkipShippingRates() {
        scrubShippingRates()
        return this.props.history.push('/cart/order-summary')
    }

    onSubmit() {
        if (!this.items.length) {
            return (window.location.href = '/cart')
        }

        let current_state = {...this.SESSION}

        let shipTo = {...this.state}
        delete shipTo.loading
        current_state.cart.shipTo = shipTo

        this.stateful(current_state)

        let payload = {
            items: this.items.map(({img_64, ...filter}) => ({...filter})),
            ship_to: shipTo,
        }

        this.setState({loading: true})

        this.REST.POST('get_shipping_rates', payload)
        .then(response => this.handleResponse(response))
    }

    handleResponse(response) {
        this.setState({loading: false})

        if (response.status === 200) {
            let current_state = {...this.SESSION}
            current_state.cart.shipment = {...response.data.payload}
            this.stateful(current_state)
            return this.props.history.push('/cart/shipping-rates')
        }
    }

    initialValues() {
        return {
            name: '',
            street1: '',
            unit: '',
            city: '',
            state: '',
            zip: '',
        }
    }
}
