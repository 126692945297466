import React from 'react'

import StudioButton from 'material/buttons/StudioButton'
import StudioManager from 'rest/StudioManager'


class Landing extends StudioManager {
    constructor(props) {super(props)
        this.state = {
            items: null,
            currentSlide: 0,
        }
    }

    componentDidMount() {
        this.REST.GET('preview_box_items', {payload: 15})
        .then(response => response?.data?.payload && this.setState({items: response.data.payload}))

        this.interval = setInterval(() => this.nextSlide(), 2000)
    }

     componentWillUnmount() {
        this.interval && clearInterval(this.interval)
    }

    render() {
        return (
            <div className='landing-wrapper'>
                <div className='landing'>
                    {this.renderPortalTopButtons()}
                    {this.renderSlideshow()}
                    {this.renderPortalButtons()}
                </div>
            </div>
        )
    }

    renderPortalButtons() {
        return (
            <div className='portal-buttons'>
                <StudioButton
                className='upcoming-events'
                text='Events'
                tooltipTitle='There are no current upcoming events. Check back later for updates!'
                variant='contained'
                size='medium'/>

                <StudioButton
                className='newsletter'
                text='Newsletter'
                tooltipTitle='There are no current newsletter items. Create an account to receive updates!'
                variant='contained'
                size='medium'/>
            </div>
        )
    }

    renderPortalTopButtons() {
        return (
            <div className='portal-buttons'>
                <StudioButton
                className='about'
                text='About'
                href='/about'
                tooltipTitle='Get to know the Artist.'
                variant='contained'
                size='medium'/>

                <StudioButton
                className='featured'
                text='Featured'
                href='/site/featured-items/1'
                tooltipTitle='Check out the currently featured items.'
                variant='contained'
                size='medium'/>
            </div>
        )
    }

    nextSlide() {
        this.state.currentSlide < this.state.items?.length - 1
        ? this.setState({currentSlide: this.state.currentSlide + 1})
        : this.setState({currentSlide: 0})
    }

    renderSlideshow() {
        return this.state.items && (
            <div className={'slide-in'}>
                <div
                className='img'
                style={{
                    backgroundImage: `url('${this.state.items[this.state.currentSlide]?.thumb}')`
                }}/>
            </div>
        )
    }
}


export default Landing
