import {formatter} from 'util/CurrencyFormatter'
import {removeFromCart} from 'app/cart/utils/CartManage'
import StudioManager from 'rest/StudioManager'
import StudioButton from 'material/buttons/StudioButton'


class CartItems extends StudioManager {
    constructor(props) {super(props)
        this.state = {
            items: this.SESSION.cart.items,
        }
    }

    render() {
        return (
            <div className='cart-wrapper'>
                {this.renderCartItems()}
            </div>
        )
    }

    renderCartItems() {
        return (
            <>
                {this.state.items.length ? (
                    this.state.items.map(item => {
                        return (
                            <div className='cart-item' key={item.img_file_name}>
                                <div className='cart-item-info'>
                                    <img src={item.thumb} alt={item.img_file_name}/>
                                    <span>
                                        <div>{item.title || item.img_file_name}</div>
                                        <div>{formatter.format(item.price)}</div>
                                    </span>

                                    <StudioButton
                                    className='cart-item-x'
                                    text='X'
                                    tooltipTitle='Remove item from cart.'
                                    variant='text'
                                    color='primary'
                                    onClick={() => {this.handleRemoveCartItem(item)}}/>
                                </div>
                            </div>
                        )
                    })
                ) : <p>There are no items in your cart</p>}
            </>
        )
    }

    handleRemoveCartItem(item) {
        removeFromCart(item)
        this.setState({items: this.SESSION.cart.items})
    }
}


export default CartItems
