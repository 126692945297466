import React from 'react'

import StudioManager from 'rest/StudioManager'


class UserPanel extends StudioManager {
    constructor() {super()
        this.state = {
            userList: null,
        }
    }

    componentDidMount() {
        this.REST.GET('user_panel').then(response => {
            response?.data?.payload && this.setState({userList: response.data.payload})
        })
    }

    render() {
        return (
            <div className='studio-collapse-table-wrapper'>
                <div className='studio-collapse-table'>
                    {this.renderTable()}
                </div>
            </div>
        )
    }

    renderTable() {
        return (
            <table className='collapse-table'>
                <tbody className='collapse-table-body'>
                    {this.state.userList && this.renderTableHeaders()}
                    {this.state.userList?.map((item, index) => {
                        return this.renderRow(item)
                    })}
                </tbody>
            </table>
        )
    }

    renderTableHeaders() {
        return (
            <tr className='collapse-table-headers'>
                <th scope='col'>User</th>
                <th scope='col'>Email</th>
                <th scope='col'>Permissions</th>
            </tr>
        )
    }

    renderRow(userData) {
        return (
            <tr key={userData.username}>
                <td>{userData.username}</td>
                <td>{userData.email}</td>
                <td>N/A</td>
            </tr>
        )
    }
}


export default UserPanel
