import StudioButton from 'material/buttons/StudioButton'
import StudioForm, {yup} from 'studio/StudioForm'
import StudioLoading from 'studio/StudioLoading'
import StudioTitleBar from 'studio/StudioTitleBar'


class NormalizeImages extends StudioForm {
    constructor(props) {super(props)
        this.state = {loading: false}
    }

    renderChildren() {
        return (
            <>
                <h5>Normalize all StudioItem image size.</h5>
                <StudioButton
                type='submit'
                text='Normalize Images'
                variant='contained'
                size='large'/>
            </>
        )
    }

    formSchema() {
        return yup.object().shape({
            emptyString: yup.string()
        })
    }

    initialValues() {
        return {
            emptyString: '',
        }
    }

    formID() {
        return 'normalize-images-form'
    }

    onSubmit() {
        this.setState({loading: true})

        this.REST.POST('normalize_images')
        .then(() => this.setState({loading: false}))
    }

     renderTitleBar() {
        return (
            <>
                {this.state.loading && <StudioLoading/>}
                <StudioTitleBar title='Normalize Images'/>
            </>
        )
    }
}

export default NormalizeImages
