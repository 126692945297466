const STUDIO_ENV = {
    // https://create-react-app.dev/docs/adding-custom-environment-variables/
    // https://stackoverflow.com/questions/48699820/how-do-i-hide-api-key-in-create-react-app
    // *** client side IDs only
    // *** DO NOT ADD API KEYS HERE AS THEY WILL BE EXPOSED AT BUILD TIME
    REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
    REACT_APP_SQUARE_SANDBOX_ID: process.env.REACT_APP_SQUARE_SANDBOX_ID,
    REACT_APP_SQUARE_ID: process.env.REACT_APP_SQUARE_ID,
    IS_PRODUCTION: process.env.NODE_ENV === 'production' ? true : false,
}

export default STUDIO_ENV
