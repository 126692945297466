import React from 'react'

import BleachBGForm from './components/BleachBG'
import Fusion from './components/Fusion'
import GenerateThumbs from './components/GenerateThumbs'
// import ItemConverter from './components/ItemConverter'
import NewStudioItem from './components/NewStudioItem'
import NewStudioItemCollection from './components/NewStudioItemCollection'
import NormalizeImages from './components/NormalizeImages'
import {Route} from 'react-router-dom'
import StudioButton from 'material/buttons/StudioButton'
import StudioManager from 'rest/StudioManager'
import StudioToolbar from 'studio/StudioToolbar'
import UserPanel from './components/UserPanel'


const BossPanelRoutes = ['/panel', '/panel/:page']


class BossPanel extends StudioManager {
    constructor() {super()
        this.state = {
            boss: false,
            supe: false,
            editMode: this.SESSION.mode,
        }
    }

    componentDidMount() {
        this.REST.GET('boss').then(response => {
            if (response?.status === 200) {
                return this.setState({boss: true})
            }
        })

        this.REST.GET('supe').then(response => {
            if (response?.status === 200) {
                return this.setState({supe: true})
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.boss && this.renderButtonGroup()}
                <Route path='/panel/new-studio-item' exact component={NewStudioItem}/>
                {/* <Route path='/panel/convert-items' exact component={ItemConverter}/> */}
                <Route path='/panel/generate-thumbs' exact component={GenerateThumbs}/>
                <Route path='/panel/normalize-images' exact component={NormalizeImages}/>
                <Route path='/panel/new-studio-item-collection' exact component={NewStudioItemCollection}/>
                <Route path='/panel/bleach-bg' exact component={BleachBGForm}/>
                <Route path='/panel/fusion' exact component={Fusion}/>
                <Route path='/panel/user-panel' exact component={UserPanel}/>
            </React.Fragment>
        )
    }

    renderButtonGroup() {
        return <StudioToolbar children={this.renderToolbarChildren()}/>
    }

    disableEditing() {
        this.stateful({mode: false})
        this.setState({editMode: false})
    }

    renderToolbarChildren() {
        return (
            <div className='panel-button-group'>
                <StudioButton
                className='panel-button'
                text='New Studio Item'
                tooltipTitle='Create a new item.'
                tooltipPlacement='bottom'
                href='/panel/new-studio-item'
                variant='text'
                color='secondary'
                onClick={() => this.disableEditing()}/>

                <StudioButton
                className='panel-button'
                text='Bleach BG'
                href='/panel/bleach-bg'
                variant='text'
                color='secondary'/>

                <StudioButton
                className='panel-button'
                text='Fusion'
                href='/panel/fusion'
                variant='text'
                color='secondary'/>

                {this.state.supe &&
                    <React.Fragment>
                        {/* <StudioButton
                        text='Item Converter'
                        href='/panel/convert-items'/>} */}

                        <StudioButton
                        className='panel-button'
                        text='Generate Thumbs'
                        href='/panel/generate-thumbs'
                        variant='text'
                        color='secondary'/>

                        <StudioButton
                        className='panel-button'
                        text='Normalize Images'
                        href='/panel/normalize-images'
                        variant='text'
                        color='secondary'/>

                        <StudioButton
                        className='panel-button'
                        text='New Collection'
                        href='/panel/new-studio-item-collection'
                        variant='text'
                        color='secondary'/>

                        <StudioButton
                        className='panel-button'
                        text='Show Users'
                        href='/panel/user-panel'
                        variant='text'
                        color='secondary'/>
                    </React.Fragment>}

                {this.renderModeButton()}
            </div>
        )
    }

    renderModeButton() {
        let buttonText = this.state.editMode ? (
            'Disable Editing'
        ) : 'Enable Editing'

        return (
            <StudioButton
            text={buttonText}
            tooltipTitle='Enable/Disable item edit mode.'
            tooltipPlacement='bottom'
            onClick={() => this.handleMode()}
            variant='contained'
            size='large'/>
        )
    }

    handleMode() {
        if (this.state.boss) {
            this.stateful({mode: !this.SESSION.mode})
            this.setState({editMode: !this.state.editMode})
            window.location = '/panel'
        }
    }

    enableMode() {}

    disableMode() {}
}

BossPanel.routes = BossPanelRoutes


export default BossPanel
