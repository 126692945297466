import React from 'react'
import {Route} from 'react-router-dom'

import ConfirmCodeRegisterForm from './components/ConfirmCodeRegisterForm'
import ConfirmCodeResetForm from './components/ConfirmCodeResetForm'
import EmailResetForm from './components/EmailResetForm'
import SignInForm from './components/SignInForm'
import SignUpForm from './components/SignUpForm'
import UpdatePasswordForm from './components/UpdatePasswordForm'


const AuthRoutes = ['/register/:page',]


class Auth extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Route path='/register/sign-in' exact component={SignInForm}/>
                <Route path='/register/sign-up' exact component={SignUpForm}/>
                <Route path='/register/confirm-code' exact component={ConfirmCodeRegisterForm}/>
                <Route path='/register/email-reset' exact component={EmailResetForm}/>
                <Route path='/register/confirm-code-form' exact component={ConfirmCodeResetForm}/>
                <Route path='/register/update-password-form' exact component={UpdatePasswordForm}/>
            </React.Fragment>
        )
    }
}

Auth.routes = AuthRoutes


export default Auth
