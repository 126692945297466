import React from 'react'
import {Helmet} from 'react-helmet'

function StudioHelmet(props) {
    const KEYWORDS = [
        'art',
        'art gallery',
        'paintings',
        'sculptures',
        'lighting',
        'furniture',
        'signs',
        'installations',
        'chattanooga art gallery',
        'blue light studio',
    ].join(', ')

    const CONTENT = [
        'Art & Gallery -',
        'paintings,',
        'sculptures,',
        'lighting,',
        'furniture,',
        'signs,',
        'custom design',
        'and installation, in Chattanooga Tennessee.',
    ].join(' ')

    const METADATA = {
        title: props.location.pathname.replaceAll('/', ' ').trim() || 'Blue Light Studio',
        description: {
            name: 'description',
            content: CONTENT,
        },
        keywords: {
            name: 'keywords',
            content: KEYWORDS,
        },
    }

    return (
        <Helmet>
            <title>
                {METADATA.title}
            </title>
            <meta {...METADATA.description}/>
            <meta {...METADATA.keywords}/>
            <meta name='robots' content='NOODP'/>
            <meta name='robots' content='nosnippet'/>
        </Helmet>
    )
}

export default StudioHelmet
