import React from 'react'

import {formatter} from 'util/CurrencyFormatter'
import {Formik} from 'formik'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import StudioManager from 'rest/StudioManager'
import StudioTitleBar from 'studio/StudioTitleBar'
import StudioButton from 'material/buttons/StudioButton'


class ShipRates extends StudioManager {
    constructor(props) {super(props)
        this.shipment = this.SESSION.cart.shipment || null
        this.selectedShippingRate = 'local'
    }

    componentDidMount() {
        let current_state = {...this.SESSION}
        current_state.cart.selectedShippingRate = 'local'

        this.stateful(current_state)
    }

    render() {
        return (
            <Formik initialValues='local' onSubmit={async () => this.onSubmit()}>
                {props => (
                    <div className='studio-form'>
                        <form onSubmit={props.handleSubmit} id='select-shipping-rate-form'>
                            <FormControl component="fieldset">

                                <StudioTitleBar title='Shipping Rates'/>

                                <RadioGroup defaultValue="local" name="select-shipping-rate-form">
                                    {this.renderLocalPickup()}
                                    {this.renderShippingOptions()}
                                </RadioGroup>

                                <StudioButton
                                type='submit'
                                text='Order Summary'
                                variant='contained'
                                size='large'
                                form='select-shipping-rate-form'/>

                            </FormControl>
                        </form>
                    </div>
                )}
            </Formik>
        )
    }

    onSubmit() {
        return this.props.history.push('/cart/order-summary')
    }

    renderLocalPickup() {
        return (
            <FormControlLabel
            value={'local'}
            label={'Local Pickup'}
            control={
                <Radio onClick={() => this.handleRadioButtonSelection('local')}/>
            }/>
        )
    }

    renderShippingOptions() {
        return (
            this.shipment?.rates.map(item => {
                return (
                    <FormControlLabel
                    key={item.object_id}
                    value={item.object_id}
                    label={`${item.provider} ${item.servicelevel.name} ${formatter.format(item.amount)}`}
                    control={
                        <Radio onClick={() => this.handleRadioButtonSelection(item)}/>
                    }/>
                )
            })
        )
    }

    handleRadioButtonSelection(option) {
        let current_state = {...this.SESSION}
        current_state.cart.selectedShippingRate = option

        this.stateful(current_state)

        return this.selectedShippingRate = option
    }
}


export default ShipRates
