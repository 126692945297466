import React from 'react'

import {FaBars} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {HomeRounded, SettingsRounded, ShoppingCartRounded,} from '@mui/icons-material'

import StudioButton from 'material/buttons/StudioButton'
import StudioManager from 'rest/StudioManager'


class Navbar extends StudioManager {
    constructor(props) {super(props)
        this.state = {
            showModal: false,
            showCog: false,
        }
    }

    render() {
        return (
            <div className='navbar'>
                {this.renderSectionLinks()}
                {this.renderTitle()}
                {this.renderHamburger()}
                {this.renderMenuModal()}
            </div>
        )
    }

    renderTitle() {
        return (
            <h1 className='navbar-header' onClick={() => this.handleLocation()}>
                Blue Light Studio
            </h1>
        )
    }

    handleLocation() {
        window.location = '/'
    }

    renderSectionLinks() {
        return (
            <div className='navbar-top-nav'>
                <StudioButton
                className='studio-toolbar-button'
                text='Decor'
                href='/site/decor'
                tooltipTitle='Visit this collection'
                tooltipPlacement='bottom'
                variant='text'
                color='primary'/>

                <StudioButton
                className='studio-toolbar-button'
                text='Sculptures'
                href='/site/sculptures'
                tooltipTitle='Visit this collection'
                tooltipPlacement='bottom'
                variant='text'
                color='primary'/>

                <StudioButton
                className='studio-toolbar-button'
                text='Wall'
                href='/site/wall'
                tooltipTitle='Visit this collection'
                tooltipPlacement='bottom'
                variant='text'
                color='primary'/>

                <StudioButton
                className='studio-toolbar-button'
                text='Signs'
                href='/site/signs'
                tooltipTitle='Visit this collection'
                tooltipPlacement='bottom'
                variant='text'
                color='primary'/>
            </div>
        )
    }

    renderHamburger() {
        return (
            <div className='navbar-hamburger' onClick={() => this.setState({
                showModal: !this.state.showModal,
            })}>
                <FaBars/>
            </div>
        )
    }

    renderMenuModal() {
        if (this.hasAccessToken && this.state.showModal && !this.state.showCog) {
            this.REST.GET('show_panel').then(response => {
                response.status === 200 && this.setState({showCog: true})
            })
        }
        return (
            this.state.showModal &&
            <div className='navbar-modal'>
                {this.renderAccountOptions()}
            </div>
        )
    }

    renderAccountOptions() {
        return (
            <div className='account-options-wrapper'>
                <h5>session</h5>
                <div className='account-options' onClick={() => this.setState({showModal: false})}>
                    {this.hasAccessToken ? (
                        this.renderOptions_isSession()
                    ) : (
                        this.renderOptions_isNotSession()
                    )}

                    {this.renderAccountOptionsIcons()}
                </div>
            </div>
        )
    }

    renderOptions_isSession() {
        return (
            <React.Fragment>
                <h6>end session</h6>
                <StudioButton
                text='Sign Out'
                variant='contained'
                size='large'
                onClick={() => this.logOut()}/>

                <h6>order history</h6>
                <StudioButton
                text='View Orders'
                href='/orders'
                variant='contained'
                size='large'/>

                {/* <h6>update account</h6>
                <StudioButton
                text='Account Settings'
                href='/auth/update'
                variant='outlined'/> */}
            </React.Fragment>
        )
    }

    renderOptions_isNotSession() {
        return (
            <React.Fragment>
                <h6>sign in</h6>
                <StudioButton
                text='Sign In'
                href='/register/sign-in'
                variant='contained'
                size='large'/>

                <h6>don't have an account?</h6>
                <StudioButton
                text='Sign Up'
                href='/register/sign-up'
                variant='contained'
                size='large'/>
            </React.Fragment>
        )
    }

    renderAccountOptionsIcons() {
        return (
            <div className='account-options-icon-bar'>
                {this.renderHomeLink()}
                {this.renderPanelLink()}
                {this.renderCartLink()}
            </div>
        )
    }

    renderHomeLink() {
        return (
            <Link to='/'>
                <HomeRounded className='studio-toolbar-settings-button'/>
            </Link>
        )
    }

    renderCartLink() {
        return this.hasAccessToken && (
            <Link to='/cart'>
                <ShoppingCartRounded className='studio-toolbar-cart-button'/>
            </Link>
        )
    }

    renderPanelLink() {
        return this.state.showCog && (
            <Link to='/panel'>
                <SettingsRounded className='studio-toolbar-settings-button'/>
            </Link>
        )
    }

    logOut() {
        return (
            this.REST.POST('logout').then(() => sessionStorage.removeItem('context'))
        )
    }
}


export default Navbar
