import React from 'react'
import PropTypes from 'prop-types'

let StudioGenericLabelPropTypes = {
    text: PropTypes.string.isRequired,
}

let StudioGenericLabelDefaultProps = {}


class StudioGenericLabel extends React.Component {
    render() {
        return (
            <React.Fragment>
                <span className='studio-generic-label'>
                    <h4>{this.props.text}</h4>
                </span>
            </React.Fragment>
        )
    }
}


StudioGenericLabel.propTypes = StudioGenericLabelPropTypes;
StudioGenericLabel.defaultProps = StudioGenericLabelDefaultProps;

export default StudioGenericLabel
