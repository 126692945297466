import React from 'react'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'

import ErrorBoundary from 'util/errors/ErrorBoundary'
import MainRouter from './MainRouter'
import {theme} from 'material/theme/Theme'


class Main extends React.Component {
    render() {
        return (
            <ErrorBoundary>
                <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                        <MainRouter/>
                    </StyledEngineProvider>
                </ThemeProvider>
            </ErrorBoundary>
        )
    }
}


export default Main
