import React from 'react'

import MenuItem from '@mui/material/MenuItem'


export function renderUnitedStatesSelector() {
    return (
        [
            <MenuItem value='AL' key='AL'>Alabama</MenuItem>,
            <MenuItem value='AK' key='AK'>Alaska</MenuItem>,
            <MenuItem value='AZ' key='AZ'>Arizona</MenuItem>,
            <MenuItem value='AR' key='AR'>Arkansas</MenuItem>,
            <MenuItem value='CA' key='CA'>California</MenuItem>,
            <MenuItem value='CO' key='CO'>Colorado</MenuItem>,
            <MenuItem value='CT' key='CT'>Connecticut</MenuItem>,
            <MenuItem value='DE' key='DE'>Delaware</MenuItem>,
            <MenuItem value='DC' key='DC'>District Of Columbia</MenuItem>,
            <MenuItem value='FL' key='FL'>Florida</MenuItem>,
            <MenuItem value='GA' key='GA'>Georgia</MenuItem>,
            <MenuItem value='HI' key='HI'>Hawaii</MenuItem>,
            <MenuItem value='ID' key='ID'>Idaho</MenuItem>,
            <MenuItem value='IL' key='IL'>Illinois</MenuItem>,
            <MenuItem value='IN' key='IN'>Indiana</MenuItem>,
            <MenuItem value='IA' key='IA'>Iowa</MenuItem>,
            <MenuItem value='KS' key='KS'>Kansas</MenuItem>,
            <MenuItem value='KY' key='KY'>Kentucky</MenuItem>,
            <MenuItem value='LA' key='LA'>Louisiana</MenuItem>,
            <MenuItem value='ME' key='ME'>Maine</MenuItem>,
            <MenuItem value='MD' key='MD'>Maryland</MenuItem>,
            <MenuItem value='MA' key='MA'>Massachusetts</MenuItem>,
            <MenuItem value='MI' key='MI'>Michigan</MenuItem>,
            <MenuItem value='MN' key='MN'>Minnesota</MenuItem>,
            <MenuItem value='MS' key='MS'>Mississippi</MenuItem>,
            <MenuItem value='MO' key='MO'>Missouri</MenuItem>,
            <MenuItem value='MT' key='MT'>Montana</MenuItem>,
            <MenuItem value='NE' key='NE'>Nebraska</MenuItem>,
            <MenuItem value='NV' key='NV'>Nevada</MenuItem>,
            <MenuItem value='NH' key='NH'>New Hampshire</MenuItem>,
            <MenuItem value='NJ' key='NJ'>New Jersey</MenuItem>,
            <MenuItem value='NM' key='NM'>New Mexico</MenuItem>,
            <MenuItem value='NY' key='NY'>New York</MenuItem>,
            <MenuItem value='NC' key='NC'>North Carolina</MenuItem>,
            <MenuItem value='ND' key='ND'>North Dakota</MenuItem>,
            <MenuItem value='OH' key='OH'>Ohio</MenuItem>,
            <MenuItem value='OK' key='OK'>Oklahoma</MenuItem>,
            <MenuItem value='OR' key='OR'>Oregon</MenuItem>,
            <MenuItem value='PA' key='PA'>Pennsylvania</MenuItem>,
            <MenuItem value='RI' key='RI'>Rhode Island</MenuItem>,
            <MenuItem value='SC' key='SC'>South Carolina</MenuItem>,
            <MenuItem value='SD' key='SD'>South Dakota</MenuItem>,
            <MenuItem value='TN' key='TN'>Tennessee</MenuItem>,
            <MenuItem value='TX' key='TX'>Texas</MenuItem>,
            <MenuItem value='UT' key='UT'>Utah</MenuItem>,
            <MenuItem value='VT' key='VT'>Vermont</MenuItem>,
            <MenuItem value='VA' key='VA'>Virginia</MenuItem>,
            <MenuItem value='WA' key='WA'>Washington</MenuItem>,
            <MenuItem value='WV' key='WV'>West Virginia</MenuItem>,
            <MenuItem value='WI' key='WI'>Wisconsin</MenuItem>,
            <MenuItem value='WY' key='WY'>Wyoming</MenuItem>,
        ]
    )
}
