import React from 'react'

import StudioButton from 'material/buttons/StudioButton'
import StudioCollapseTable from 'studio/StudioCollapseTable'
import StudioInput from 'material/input/StudioInput'
import StudioManager from 'rest/StudioManager'


class OrderHistroyList extends StudioManager {
    constructor(props) {super(props)
        this.state={
            orders: null,
            filterByType: 'Email',
            filterByValue: '',
        }

        this.valueMap = {
            'Name': 'order_username',
            'Email': 'order_email',
            'Date': 'date_created',
        }
    }

    componentWillMount() {
        this.REST.GET('order_history_list')
        .then(response => this.setState({orders: response.data.payload}))
    }

    render() {
        return (
            <React.Fragment>
                {this.renderFilters()}
                <StudioCollapseTable children={this.ordersList()}/>
            </React.Fragment>
        )
    }

    renderFilters() {
        return this.SESSION.mode && (
            <div className='order-filters'>
                {this.renderFilterButtons()}
                <StudioInput
                type='text'
                labelText={`Filter By ${this.state.filterByType}`}
                onChange={event => this.setState({filterByValue: event.target.value})}/>
            </div>
        )
    }

    renderFilterButtons() {
        let style = value => {
            if (value === this.state.filterByType) {
                return {color: '#FFF'}
            }
        }

        return (
            ['Name', 'Email', 'Date'].map(value => {
                return (
                    <StudioButton
                    style={style(value)}
                    value={value}
                    text={value}
                    variant='contained'
                    size='large'
                    onClick={() => this.setState({filterByType: value})}/>
                )
            })
        )
    }

    ordersList() {
        if (!this.state.orders) {
            return null
        }

        return this.state.filterByValue ? (
            this.state.orders.filter(order => {
                return (
                    order[this.valueMap[this.state.filterByType]]
                ).toLowerCase().startsWith(
                    (this.state.filterByValue).toLowerCase()
                )
            })
        ) : this.state.orders || null
    }
}


export default OrderHistroyList
