import React from 'react'

import {Link} from 'react-router-dom'
import StudioGenericLabel from 'studio/StudioGenericLabel'


class CollectionItems extends React.Component {
    constructor(props) {super(props)
        this.collection = this.props[0]
        this.items = this.props[1]
        this.premierItem = this.items.filter(item => item.is_premier_item)
        this.featuredItems = this.items.filter(item => item.is_featured_item === true) || []
    }

    componentDidMount() {}

    render() {
        return (
            <div className='collection-wrapper'>
                <div className='collection'>
                    {this.renderCollectionHeader()}
                    {this.renderPremierItem()}
                    {this.renderFeatureItems()}
                </div>
            </div>
        )
    }

    renderCollectionHeader() {
        return <StudioGenericLabel text={this.collection}/>
    }

    renderPremierItem() {
        return (
            <Link to={`/collection/${this.collection}/1`}>
                <img
                className='premier-item'
                alt='img'
                src={this.premierItem[0]?.thumb || this.items[5]?.thumb || this.items[0]?.thumb}/>
            </Link>
        )
    }

    renderFeatureItems() {
        for (let index = this.featuredItems.length + 1; index < 5; index++) {
            if (this.items[index]) {
                this.featuredItems.push(this.items[index])
            } else {
                break
            }
        }

        return (
            <div className='feature-items'>
                {this.featuredItems.map((item, index) => {
                    let key = item.title || item.img_file_name
                    return (
                        <div key={key} className='feature-item-wrapper'>
                            <Link to={`/collection/${this.collection}/1`}>
                                <img src={item.thumb} alt={String(index)}/>
                            </Link>
                        </div>
                    )
                })}
            </div>
        )
    }
}


export default CollectionItems
