import React from 'react'

import CollectionItems from './CollectionItems'
import MasonryGrid from 'material/grid/MasonryGrid'
import StudioManager from 'rest/StudioManager'


class Section extends StudioManager {
    constructor(props) {super(props)
        this.state = {items: []}
    }

    componentDidMount() {
        let payload = this.props.match.params.section

        payload && this.REST.GET('studio_section', {payload: payload})
        .then(response => {
            this.setState({
                items: Object.entries(response?.data?.payload || {})
            })
        })
    }

    render() {
        return(
            <React.Fragment>
                {this.renderCollections()}
            </React.Fragment>
        )
    }

    renderCollections() {
        let children = this.state.items.map((item, index) => {
            return <CollectionItems {...item} key={String(index)}/>
        })

        return <MasonryGrid children={children}/>
    }
}


export default Section
