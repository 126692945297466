import {createTheme} from '@mui/material/styles'


export const theme = createTheme({
    // @studio-blue:
    // @studio-light-blue: #C9DFEC
    // @studio-dark-blue: #B3CFEC
    // @studio-light-background: #393939
    // @studio-dark-background: #2d2d2d

    components: {
        MuiButton: {
            styleOverrides: {
                root: { // MuiButton-root
                    lineHeight: '1',
                    maxHeight: '30px',
                    padding: '3px 5px',
                    fontSize: '0.75rem',
                    fontWeight: '600',
                    borderRadius: '5px',
                    boxShadow: 'none',
                    "&:hover": {
                        boxShadow: 'none',
                    },
                },
                containedSizeLarge: { // variant='contained' size='large'
                    color: '#2d2d2d',
                    backgroundColor: '#C9DFEC',
                    border: '1px solid #2d2d2d',
                    padding: '10px',
                    "&:hover": {
                        color: '#C9DFEC',
                        backgroundColor: 'black',
                        border: '1px solid #2d2d2d',
                    },
                },
                containedSizeMedium: { // variant='contained' size='medium'
                    maxHeight: '35px',
                    color: 'black',
                    margin: '25px 15px',
                    padding: '10px',
                    backgroundColor: '#C9DFEC',
                    "&:hover": {
                        color: '#C9DFEC',
                        backgroundColor: 'black',
                    },
                },
                textPrimary: { // variant='text' color='primary'
                    color: '#C9DFEC',
                    border: '1px solid transparent',
                    backgroundColor: 'transparent',
                    borderRadius: '99px',
                    padding: '10px 5px',
                    fontWeight: '700',
                    "&:hover": {
                        backgroundColor: 'black',
                    },
                },
                textSecondary: { // variant='text' color='secondary'
                    color: '#C9DFEC',
                    padding: '5px',
                    backgroundColor: 'transparent',
                    borderRadius: '99px !important',
                    textTransform: 'capitalize',
                    "&:hover": {
                        backgroundColor: 'black',
                    },
                },
            },
        },

        MuiFormLabel: {
            styleOverrides: {
                root: {
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: '5px 10px',
                    maxHeight: '35px',
                    // color: 'green',
                    // backgroundColor: 'green',
                },
                input: {
                    // padding: '0',
                    // background: 'transparent',
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                },
                focused: {
                },
                animated: {
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                },
                focused: {
                },
            },
        },

        MuiPaginationItem: {
            styleOverrides: {
                page: {
                    // color: 'green',
                    // color: '#C9DFEC',
                },
                previousNext: {
                    // color: 'green'
                    // color: '#C9DFEC',
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    // color: 'green',
                    // background: 'green',
                    '& label': {
                        // color: 'green',
                    },
                    '& label.Mui-focused': {
                        // color: 'green',
                    },
                    '& .MuiInput-underline:after': {
                        // borderBottomColor: 'green',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            // borderColor: 'green',
                        },
                        '&:hover fieldset': {
                            // borderColor: 'green',
                        },
                        '&.Mui-focused fieldset': {
                            // borderColor: 'green',
                        },
                    },
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: '#C9DFEC',
                    backgroundColor: 'black',
                },
            },
        },
    },

    typography: {
        fontFamily: [
            'Ubuntu', // matching order in index.less
            'sans-serif',
            'Segoe UI Emoji',
            'Segoe UI Symbol',
        ].join(',')
    },
})
