import {getGlobal} from 'reactn'
import {sparrow, song} from 'util/sparrow/Sparrow.js'
import {stateful} from 'app/MainContext.js'
import {hasAccessToken} from 'rest/Session'

export function scrubShippingRates() {
    const cart = getGlobal().cart

    if (cart.selectedShippingRate) {
        delete cart.selectedShippingRate
        delete cart.shipment
    }

    stateful({cart: cart})
}

export function addToCart(item) {
    if (!hasAccessToken()) {
        return sparrow({option: song.SUGGEST_LOGIN})
    }

    const cart = getGlobal().cart

    if (cart.items.find(c => c.img_file_name === item.img_file_name)) {
        sparrow({option: song.ALREADY_IN_CART})
        return
    }

    cart.items.push(item)
    cart.count += 1

    stateful({cart: cart})

    sparrow({option: song.ADDED_TO_CART, options: item.title})

    scrubShippingRates()
}

export function removeFromCart(item) {
    const cart = getGlobal().cart

    cart.items = [...cart.items].filter(c => c.img_file_name !== item.img_file_name)
    cart.count -= 1

    stateful({cart: cart})

    scrubShippingRates()
}
