// import collection_ph from 'assets/collection_ph.png'
import React from 'react'

import NewStudioItem from './NewStudioItem'
import {sparrow, song} from 'util/sparrow/Sparrow'
import StudioInput from 'material/input/StudioInput'
import StudioTitleBar from 'studio/StudioTitleBar'


class NewStudioItemCollection extends NewStudioItem {
    renderFileUpload() {}
    renderInputs() {}
    renderPreviewItem() {}

    renderTitleBar() {
        return <StudioTitleBar title='Add New Collection'/>
    }

    renderCollectionInput(props) {
        return (
            <StudioInput
            select={false}
            children={this.renderCollectionOptions()}
            name='collection'
            labelText='Collection'
            required={true}
            value={props.values.collection}
            error={props.errors.collection}
            inputProps={{onChange: props.handleChange}}
            onChange={event => this.setState({collection: event.target.value})}/>
        )
    }

    onSubmit() {
        let payload = {
            section: this.state.section,
            collection: this.state.collection,
            img_file_name: `${this.state.collection}_temp`,
            // img_64: this.state.img_64,
        }

        this.REST.POST('new_studio_item_collection', payload)
        .then(response => {
            if (response.status === 200) {
                sparrow({option: song.ADDED_TO_SHOP})
                .then(() => window.location.reload())
            }
        })
    }
}


export default NewStudioItemCollection
