import swal from 'sweetalert'

export const song = {
    ADDED_TO_CART: 'addedToCart',
    ADDED_TO_GALLERY: 'addedToGallery',
    ADDED_TO_SHOP: 'addedToShop',
    ALREADY_IN_CART: 'alreadyInCart',
    ALREADY_SIGNED_IN: 'alreadySignedIn',
    CONFIRM_CODE_SENT: 'confirmCodeSent',
    EMAIL_CONFIRMED: 'emailConfirmed',
    FUSE_FIRST: 'fuseFirst',
    INTEGRITY_ERROR: 'integrityError',
    KEYCODE_EXPIRED: 'keyCodeExpired',
    KEYCODE_REJECTED: 'keyCodeRejected',
    SOMETHING_WENT_WRONG: 'somethingWentWrong',
    SUGGEST_LOGIN: 'suggestLogIn',
    TRANSACTION_FAILED: 'transactionFailed',
}

export async function sparrow({ option = null, callback = null, options = null } = {}) {

    const sweetAlert = {

        'addedToCart': {
            title: options,
            text: 'Added to cart',
            timer: 5000,
        },

        'addedToGallery': {
            title: 'Success',
            text: 'Gallery Item Added to Site',
            timer: 2000,
        },

        'addedToShop': {
            title: 'Success',
            text: 'Shop Item Added to Site',
            timer: 2000,
        },

        'alreadyInCart': {
            title: 'Item already in cart',
            text: 'Open shopping cart to manage items',
            timer: 5000,
        },

        'alreadySignedIn': {
            title: 'Already Signed In',
            text: 'Redirecting..',
            timer: 5000,
            button: { text: 'Ok', closeModal: true },
        },

        'badLoginCredentials': {
            title: 'Not Found',
            text: 'Incorrect email and/or password',
            timer: 5000,
            button: { text: 'Continue', closeModal: true },
        },

        'confirmCodeSent': {
            title: `Email confirmation code sent to ${options}`,
            text: 'Please verify your email address\n\nThis code will expire in 15 mins',
        },

        'emailConfirmed': {
            title: 'Email Confirmed!',
            text: 'Sign-in to continue',
            button: { text: 'Ok', closeModal: true },
        },

        'fuseFirst': {
            title: 'Fusion Required',
            text: 'Please fuse images first',
            timer: 5000,
        },

        'integrityError': {
            title: 'Item Already Exists..',
            text: 'The database contains this file or title\n\nIt cannot be duplicated'
        },

        'keyCodeExpired': {
            title: 'Key Code Expired',
            text: 'Redirecting..',
            timer: 5000,
        },

        'keyCodeRejected': {
            title: 'Key Code Rejected',
            timer: 5000,
        },

        'somethingWentWrong': {
            title: 'Something went wrong..',
            timer: 5000,
        },

        'suggestLogIn': {
            title: 'Account Required',
            text: 'Sign In to Proceed',
            timer: 5000,
        },

        'transactionFailed': {
            title: 'Transaction Failed',
            text: 'Verify shipping address and/or card info\n\nContact seller if problem persists',
            timer: 5000,
        }

    }

    return await swal(sweetAlert[option])
}
