import React from 'react'
import PropTypes from 'prop-types'

import {addToCart} from 'app/cart/utils/CartManage'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {formatter} from 'util/CurrencyFormatter'
import StudioButton from 'material/buttons/StudioButton'
import StudioGenericLabel from './StudioGenericLabel'
import StudioInput from 'material/input/StudioInput'
import StudioItemPreviewModal from 'studio/StudioItemPreviewModal'
import StudioManager from 'rest/StudioManager'


let StudioItemPropTypes = {
    section: PropTypes.string,
    collection: PropTypes.string,
    is_premier_item: PropTypes.bool,
    is_featured_item: PropTypes.bool,
    img_file_name: PropTypes.string,
    img_64: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    depth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
}

let StudioItemDefaultProps = {
    is_premier_item: false,
    is_featured_item: false,
}


class StudioItem extends StudioManager {
    constructor(props) {super(props)
        this.state = Object.assign({
            showPreviewModal: false,
            fullImage: null,
        }, this.props)
        this.mode = this.SESSION.mode || false
        this.isPreview = this.props.isPreview || false
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({...this.props})
        }
    }

    render() {
        let className = this.mode ? 'studio-item-mode' : 'studio-item'

        if (this.state.isPreview) {
            className = `${className} studio-item-preview`
        }

        return (
            <div className={className}>
                {this.state.showPreviewModal &&
                    <StudioItemPreviewModal
                    img_64={this.state.fullImage}
                    title={this.props.title || this.props.img_file_name}
                    handleClose={() => this.handleClosePreviewModal()}/>}

                <Card className='studio-item-card'>
                    <StudioGenericLabel text={this.state.title || this.state.img_file_name}/>
                    {this.renderCardMedia()}
                    {this.renderCardContent()}
                </Card>
            </div>
        )
    }

    handleClosePreviewModal() {
        return this.setState({showPreviewModal: false})
    }

    handleOpenPreviewModal() {
        this.REST.GET('fetch_full_image', {payload: this.props.img_file_name})
        .then(response => this.setState({
            showPreviewModal: true,
            fullImage: response.data.payload,
        }))

        return
    }

    renderCardMedia() {
        return (
            <div className='img-container' onClick={() => this.handleOpenPreviewModal()}>
                <CardMedia
                className='studio-item-card-media'
                component='img'
                src={this.props.thumb || this.props.img_64}
                height='auto'
                loading='lazy'/>
            </div>
        )
    }

    renderCardContent() {
        return (
            <CardContent className='studio-item-card-content'>
                {this.renderTitle()}
                {this.renderDescription()}
                {this.renderDimensions()}
                {this.renderAddToCartButton()}
                {this.renderPreferredOptions()}
                {this.renderSubmitButton()}
            </CardContent>
        )
    }

    renderTitle() {
        return this.mode && (
            <StudioInput
            name='title'
            disabled={!this.mode}
            labelText='title'
            value={this.state.title || (!this.mode ? this.state.img_file_name : '')}
            onChange={e => this.setState({title: e.target.value})}/>
        )
    }

    renderDescription () {
        return this.mode || this.state.description ? (
            <StudioInput
            name='description'
            disabled={!this.mode}
            labelText='description'
            value={this.state.description || ''}
            onChange={e => this.setState({description: e.target.value})}/>
        ) : null
    }

    renderDimensions() {
        let width = this.mode ? (
            this.state.width
        ) : this.state.width && `Width ${this.state.width}"`

        let height = this.mode ? (
            this.state.height
        ) : this.state.height && `Height ${this.state.height}"`

        let depth = this.mode ? (
            this.state.depth
        ) : this.state.depth && `Depth ${this.state.depth}"`

        let weight = this.mode ? (
            this.state.weight
        ) : this.state.weight && `Weight ${this.state.weight} lbs`

        return (
            <span>
                <StudioInput
                name='width'
                disabled={!this.mode}
                value={width || ''}
                labelText='width'
                onChange={e => this.setState({width: e.target.value})}/>

                <StudioInput
                name='height'
                disabled={!this.mode}
                value={height || ''}
                labelText='height'
                onChange={e => this.setState({height: e.target.value})}/>

                <StudioInput
                name='depth'
                disabled={!this.mode}
                value={depth || ''}
                labelText='depth'
                onChange={e => this.setState({depth: e.target.value})}/>

                <StudioInput
                name='weight'
                disabled={!this.mode}
                value={weight || ''}
                labelText='weight'
                onChange={e => this.setState({weight: e.target.value})}/>
            </span>
        )
    }

    renderPreferredOptions() {
        return this.mode ? (
            <fieldset className='checkbox-fieldset'>
                <StudioInput
                name='premier_item'
                labelText='Premier Item ?'
                tooltipTitle='Set the preferred image for this collection (max=1 per collection)'
                type='checkbox'
                inputProps={{
                    value: this.state.is_premier_item,
                    checked: this.state.is_premier_item ? true : false,
                }}
                onChange={() => this.setState({is_premier_item: !this.state.is_premier_item})}/>

                <StudioInput
                name='featured_item'
                labelText='Featured Item ?'
                tooltipTitle='Set the preferred preview images for this collection (max=4 per collection)'
                type='checkbox'
                inputProps={{
                    value: this.state.is_featured_item,
                    checked: this.state.is_featured_item ? true : false,
                }}
                onChange={() => this.setState({is_featured_item: !this.state.is_featured_item})}/>
            </fieldset>
        ) : null
    }

    renderPrice() {
        let value = 0

        if (this.state.price && this.state.price > 0) {
            value = formatter.format(this.state.price)
        }

        return value || this.mode ? (
            <StudioInput
            name='price'
            disabled={!this.mode}
            value={!this.mode ? value : this.state.price || ''}
            labelText='price'
            onChange={e => this.setState({price: e.target.value})}/>
        ) : (
            <StudioInput
            name='price'
            disabled={true}
            value='Sold'
            labelText='price'
            onChange={e => this.setState({price: e.target.value})}/>
        )
    }

    renderAddToCartButton() {
        return (
            <div className='cart-button-wrapper'>
                {this.renderPrice()}
                {this.hasShippingCriteria() ? (
                    <StudioButton
                    text='Add to Cart'
                    variant='contained'
                    size='large'
                    disabled={this.isPreview}
                    onClick={()=> addToCart(
                        (({showPreviewModal, ...o}) => o)(this.state)
                    )}/>
                ) : null}
            </div>
        )
    }

    hasShippingCriteria() {
        // all values must return true for valid shipping criteria
        return (
            this.state.price
            && this.state.width
            && this.state.height
            && this.state.depth
            && this.state.weight
            && !this.mode
        )
    }

    /**
     * @deprecated
     */
    renderSectionCollectionOptions() {
        return this.mode ? (
            <React.Fragment>
                <StudioInput
                name='section'
                value={this.state.section || ''}
                labelText='section'
                onChange={e => this.setState({section: e.target.value})}/>

                <StudioInput
                name='collection'
                value={this.state.collection || ''}
                labelText='collection'
                onChange={e => this.setState({collection: e.target.value})}/>
            </React.Fragment>
        ) : null
    }

    postUpdatedStudioItem() {
        let payload = {...this.state}  // shallow copy state
        delete payload.showPreviewModal  // exclude fields that don't fit StudioItem model
        delete payload.fullImage  // exclude fields that don't fit StudioItem model
        delete payload.img_64  // don't overwrite StudioItem.img_64

        this.REST.POST('update_studio_item', payload)
    }

    deleteStudioItem() {
        this.REST.POST('delete_studio_item', {payload: this.props.img_file_name})
        .then(() => window.location.reload())
    }

    renderSubmitButton() {
        return this.mode ? (
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                <StudioButton
                text='Update Item'
                variant='contained'
                size='large'
                onClick={()=> this.postUpdatedStudioItem()}/>

                <StudioButton
                text='Delete Item'
                variant='contained'
                size='large'
                onClick={() => this.deleteStudioItem()}/>
            </div>
        ) : null
    }
}


StudioItem.propTypes = StudioItemPropTypes
StudioItem.defaultProps = StudioItemDefaultProps


export default StudioItem
