import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import {Link} from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'


let StudioButtonPropTypes = {
    // Mui Button API

    // The content of the button.
    children: PropTypes.node,
    // Override or extend the styles applied to the component.
    // https://v4--material-ui-docs.netlify.app/api/button/#css
    classes: PropTypes.object,
    // The color of the component.
    // It supports those theme colors that make sense for this component.
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning', PropTypes.string]),
    // The component used for the root node.
    // Either a string to use a HTML element or a component.
    component: PropTypes.elementType,
    // If true, the button will be disabled.
    disabled: PropTypes.bool,
    // If true, no elevation is used.
    disableElevation: PropTypes.bool,
    // If true, the keyboard focus ripple will be disabled.
    disableFocusRipple: PropTypes.bool,
    // If true, the ripple effect will be disabled.
    disableRipple: PropTypes.bool,
    // Element placed after the children.
    endIcon: PropTypes.node,
    // If true, the button will take up the full width of its container.
    fullWidth: PropTypes.bool,
    // The URL to link to when the button is clicked.
    // If defined, an a element will be used as the root node.
    href: PropTypes.string,
    // The size of the button.
    size: PropTypes.oneOf(['large', 'medium', 'small', PropTypes.string]),
    // Element placed before the children.
    startIcon: PropTypes.node,
    // The system prop that allows defining system overrides as well as additional CSS styles.
    // https://mui.com/system/the-sx-prop/
    sx: PropTypes.object,
    // The variant to use.
    variant: PropTypes.oneOf(['contained', 'outlined', 'text', PropTypes.string]),

    // Studio Button API

    // The button className
    className: PropTypes.string,
    // Text to use on the button
    text: PropTypes.string,
    // Defines HTML button type Attribute
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    // Method to run when the component is clicked
    onClick: PropTypes.func,
    // Callback for when the mouse enters the button
    onMouseEnter: PropTypes.func,
    // Callback for when the mouse leaves the button
    onMouseLeave: PropTypes.func,
    // Callback for when the mouse is down on the button
    onMouseDown: PropTypes.func,
    // Callback for when the mouse is up on the button
    onMouseUp: PropTypes.func,

    // Tooltip props
    tooltipTitle: PropTypes.string,
    // Tooltip props
    tooltipPlacement: PropTypes.string,
    // Tooltip props
    tooltipDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

let StudioButtonDefaultProps = {
    // Mui default props
    children: null,
    classes: null,
    color: 'info',
    component: null,
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    endIcon: null,
    fullWidth: false,
    href: null,
    size: 'small',
    startIcon: null,
    sx: null,
    variant: 'outlined',
    // Studio default props
    className: 'studio-button',
    text: 'Studio Button',
    tooltipTitle: null,
    // Tooltip props
    tooltipPlacement: 'top',
    // Tooltip props
    tooltipDelay: 750,
    type: 'button',
    onClick: () => void (0),
    onMouseEnter: () => void (0),
    onMouseLeave: () => void (0),
    onMouseDown: () => void (0),
    onMouseUp: () => void (0),
}


class StudioButton extends React.Component {
    constructor(props) {super(props)
        this.state = {}
    }

    render() {
        return this.renderButtonWithTooltip()
    }

    renderButtonWithTooltip() {
        return (
            <Tooltip
            className='studio-tooltip'
            title={this.props.tooltipTitle}
            placement={this.props.tooltipPlacement}
            enterDelay={Number(this.props.tooltipDelay)}>
                {this.renderButton()}
            </Tooltip>
        )
    }

    renderButton() {
        let className = this.props.color || 'primary'

        return (
            <Button
            children={this.props.children}
            classes={this.props.classes}
            color={this.props.color}
            component={this.makeComponent()}
            disabled={this.props.disabled}
            disableElevation={this.props.disableElevation}
            disableFocusRipple={this.props.disableFocusRipple}
            disableRipple={this.props.disableRipple}
            endIcon={this.props.endIcon}
            fullWidth={this.props.fullWidth}
            href={this.props.href}
            size={this.props.size}
            sx={this.props.sx}
            startIcon={this.props.startIcon}
            variant={this.props.variant}
            className={`studio-button ${className} ${this.props.className}`}
            text={this.props.text}
            type={this.props.type}
            onClick={this.props.onClick}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onMouseDown={this.props.onMouseDown}
            onMouseUp={this.props.onMouseUp}>
                {this.props.text}
            </Button>
        )
    }

    makeComponent() {
        return this.props.href ? (
            React.Component(<Link to={this.props.href || null} />)
        ) : null
    }
}


StudioButton.propTypes = StudioButtonPropTypes
StudioButton.defaultProps = StudioButtonDefaultProps


export default StudioButton
