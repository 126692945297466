import MasonryGrid from 'material/grid/MasonryGrid'

import StudioItem from 'studio/StudioItem'
import StudioManager from 'rest/StudioManager'
import StudioPager from 'material/pagination/StudioPager'


class FeaturedItems extends StudioManager {
    constructor(props) {super(props)
        this.state = {
            items: [],
            page: 1,
            count: 0,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        prevState !== this.state &&
        this.props.history.replace({
            pathname: `/site/featured-items/${this.state.page}`
        })
    }

    render() {
        return this.renderPager()
    }

    makeRequest(event=null, value=null) {
        let payload = ({
            page: value || this.state.page,
        })

        return payload && (
            this.REST.GET('all_featured_items', payload)
            .then(response => response.data.payload && (
                this.setState({
                    items: response.data.payload.items,
                    page: response.data.payload.page,
                    count: response.data.payload.count,
                }),
                window.scroll({top: 0})
            ))
        )
    }

    renderItems() {
        const children = this.renderGridItems()

        return children?.length ? (
            <MasonryGrid children={children}/>
        ) : <p>There are no currently featured items. Please check back for updates.</p>
    }

    renderGridItems() {
        return (
            this.state.items?.map((item, index) => {
                return (
                    <div key={String(index)} id={item.title || item.img_file_name}>
                        <StudioItem {...item}/>
                    </div>
                )
            })
        )
    }

    renderPager() {
        return (
            <StudioPager
            top bottom
            state={this.state}
            makeRequest={() => this.makeRequest()}
            children={this.renderItems()}
            onChange={(event, value) => this.makeRequest(event, value)}/>
        )
    }
}


export default FeaturedItems
