import React from 'react'
import {Route} from 'react-router-dom'

import About from './components/About'
import Collection from './components/Collection'
import FeaturedItems from './components/FeaturedItems'
import Landing from './components/Landing'
import Section from './components/Section'


const SiteRoutes = [
    '/',
    '/about',
    '/collection/**',
    '/landing',
    '/site/:section',
    '/site/featured-items/:page',
]


class Site extends React.Component {
    constructor(props) {super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <Route path='/about' exact component={About}/>

                <Route path={[
                    '/collection/:collection',
                    '/collection/:collection/:page',
                ]} exact component={Collection}/>

                <Route path='/site/featured-items/:page' exact component={FeaturedItems}/>
                <Route path='/' exact component={Landing}/>
                <Route path='/site/:section' exact render={() => <Section {...this.props}/>}/>
            </React.Fragment>
        )
    }
}

Site.routes = SiteRoutes


export default Site
