import React from 'react'
import PropTypes from 'prop-types'

import {ButtonGroup} from '@mui/material'


let StudioToolbarPropTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    className: PropTypes.string,
}

let StudioToolbarDefaultProps = {
    children: [],
    className: null,
}


class StudioToolbar extends React.Component {
    render() {
        return (
            <div className={`studio-toolbar ${this.props.className}`}>
                <div className='studio-toolbar-button-group'>
                    <ButtonGroup className='studio-button-group' children={this.props.children}/>
                </div>
            </div>
        )
    }
}


StudioToolbar.propTypes = StudioToolbarPropTypes
StudioToolbar.defaultProps = StudioToolbarDefaultProps

export default StudioToolbar
