import React from 'react'

import Masonry from '@mui/lab/Masonry'


class MasonryGrid extends React.Component {
    render() {
        return (
            <div className='material-grid-wrapper'>
                <div className='material-grid'>
                    <Masonry
                    className='studio-masonry-grid'
                    columns={{xs: 1, sm: 2, md: 3}}
                    spacing={{xs: 3, sm: 2, md: 5}}
                    children={this.props.children}/>
                </div>
            </div>
        )
    }
}


export default MasonryGrid
